import Vue from 'vue';
import components from './lib';

// get options passed from module.js
const options = JSON.parse(`{"namespace":"ucComponents"}`);
// loop through components and register them
for (const name in components) {
  Vue.component(name, {
    // extend the original component
    extends: components[name],
    // add a _customCounterOptions prop to gain access to the options in the component
    props: {
      // eslint-disable-next-line
      _customCounterOptions: {
        type: Object,
        default: () => ({ ...options })
      }
    }
  });
}
