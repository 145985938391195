<template>
  <div v-if="slides.length" class="promotions-sliders">
    <div class="promotions-slider-arrow left" :class="slug"></div>
    <div class="promotions-slider-row">
      <client-only>
        <swiper class="promotions-slider-swiper" :options="swiperOption">
          <swiper-slide v-for="(slide, index) in slidesData" :key="`main-first-swiper-slide-${index}`">
            <slide :slide="slide" :position="index" />
          </swiper-slide>
        </swiper>

        <template slot="placeholder">
          <section class="promotions-slider-nojs">
            <uc-link
              v-for="(slide, index) in slides.slice(0, 3)"
              :key="`main-first-swiper-slide-${index}`"
              :to="slide.link || ''"
              class="promotions-slider-item"
            >
              <uc-image :img="slide.img" folder="slider-image" :alt="slide.alt" :title="slide.title" />
            </uc-link>
          </section>
        </template>
      </client-only>
    </div>
    <div class="promotions-slider-arrow right" :class="slug"></div>
    <div class="slider-pagination">
      <div slot="pagination" class="pagination-circle" :class="[slug, { 'display-none': slides.length <= 1 }]"></div>
    </div>
  </div>
</template>

<script>
import Slide from '~/src/components/main/mainSlider/Slide.vue';
import { ScreenWidthMixin } from 'u-mixins';
export default {
  components: {
    Slide
  },
  mixins: [ScreenWidthMixin],
  props: {
    slides: {
      type: Array,
      default: () => []
    },
    slug: {
      type: String,
      default: 'main-slider'
    }
  },
  data() {
    return {
      show: false,
      swiperOption: {
        spaceBetween: 20,
        slidesPerView: 'auto',
        loop: true,
        pagination: {
          el: `.${this.slug}.pagination-circle`,
          clickable: true
        },
        navigation: {
          prevEl: `.${this.slug}.left`,
          nextEl: `.${this.slug}.right`
        }
      }
    };
  },
  computed: {
    slidesData() {
      const screenType = this.isScreenMobile ? 2 : 1;
      return this.slides.filter((el) => Number(el.type) === screenType);
    }
  },
  mounted() {
    this.show = true;
    this.sendToGtmViewed();
  },
  methods: {
    getGtmPayload(slide, position) {
      if (!slide) {
        return null;
      }
      return {
        promotion_id: slide.promotion_id,
        promotion_name: slide.promotion_name,
        creative_slot: position + 1
      };
    },
    sendToGtmViewed() {
      const payload = this.slidesData.map(this.getGtmPayload);
      this.$gtm.setMultiplePromotionAction(payload, this.$gtm.constructor.PROMOTION_VIEW_KEY);
    }
  }
};
</script>

<style lang="scss">
@import '~/assets/scss/components/pagination-circle.scss';
</style>
