<template>
  <StarRating v-bind="$attrs" v-on="$listeners" />
</template>

<script>
export default {
  components: {
    StarRating: () => import(/* webpackChunkName: "vue-star-rating" */ 'vue-star-rating').then((res) => res.default)
  },
  model: {
    prop: 'rating',
    event: 'rating-selected'
  }
};
</script>
