<template>
  <div ref="scrollContainer" class="custom-scrollbar">
    <div
      ref="scrollContent"
      class="custom-scrollbar__content"
      @mousedown="startScroll($event)"
      @mouseup="stopScroll"
      @scroll="handleScroll"
      @click.capture="checkedMove"
    >
      <slot></slot>
    </div>
    <div
      v-if="hideScroll"
      ref="scrollbar"
      class="custom-scrollbar__scrollbar"
      @mousedown="startDrag"
      @click="handleScrollbarClick"
    >
      <div
        ref="scrollThumb"
        class="custom-scrollbar__scroll-thumb"
        :style="{ width: scrollbarThumbWidthPercent + '%', left: scrollbarOffsetPixel + 'px' }"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UcCustomScroll',
  data() {
    return {
      scrollbarThumbWidthPercent: 0, // Percentage width of the scrollbar thumb
      scrollbarWidthPixel: 0, // Pixel width of the scrollbar
      scrollbarOffsetPixel: 0, // Pixel offset of the scrollbar thumb
      isDragging: false, // Flag to indicate if scrollbar dragging is in progress
      scrollContainerWidth: 0, // Width of the scroll container
      scrollContentWidth: 0, // Width of the scroll content
      isScrolling: false, // Flag to indicate if scrolling is in progress
      scrollX: 0, // Horizontal scroll position
      isMoved: false
    };
  },
  computed: {
    hideScroll() {
      return this.scrollContainerWidth < this.scrollContentWidth; // Flag to indicate if the scrollbar should be hidden
    }
  },
  mounted() {
    this.updateScrollbar(); // Initialize the scrollbar
    window.addEventListener('resize', this.updateScrollbar); // Update the scrollbar on window resize
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateScrollbar); // Remove the resize event listener
    window.removeEventListener('mousemove', this.handleMouseMove); // Remove the mousemove event listener
    window.removeEventListener('mouseup', this.stopScroll); // Remove the mouseup event listener
  },
  methods: {
    updateScrollbar() {
      const scrollContainer = this.$refs.scrollContainer;
      const scrollContent = this.$refs.scrollContent;
      this.scrollContainerWidth = scrollContainer.offsetWidth; // Update the scroll container width
      this.scrollContentWidth = scrollContent.scrollWidth; // Update the scroll content width
      this.scrollbarThumbWidthPercent =
        100 - ((this.scrollContentWidth - this.scrollContainerWidth) / this.scrollContentWidth) * 100; // Calculate the percentage width of the scrollbar thumb
      this.scrollbarWidthPixel = (this.scrollContainerWidth / 100) * this.scrollbarThumbWidthPercent; // Calculate the pixel width of the scrollbar
    },
    handleScroll() {
      const scrollContent = this.$refs.scrollContent;
      this.scrollbarOffsetPixel =
        (((this.scrollContainerWidth - this.scrollbarWidthPixel) / 100) * scrollContent.scrollLeft) /
        ((this.scrollContentWidth - this.scrollContainerWidth) / 100); // Calculate the pixel offset of the thumb based on the position
    },
    startDrag(event) {
      this.isDragging = true;
      this.startX = event.clientX;
      this.startScrollLeft = this.$refs.scrollContent.scrollLeft;
      window.addEventListener('mousemove', this.onDrag);
      window.addEventListener('mouseup', this.stopDrag);
    },
    stopDrag() {
      this.isDragging = false;
      window.removeEventListener('mousemove', this.onDrag);
      window.removeEventListener('mouseup', this.stopDrag);
    },
    onDrag(event) {
      const dragDistance = event.clientX - this.startX;
      const scrollDistance =
        (dragDistance / this.$refs.scrollContainer.offsetWidth) * this.$refs.scrollContent.scrollWidth;

      this.$refs.scrollContent.scrollLeft = this.startScrollLeft + scrollDistance; // Update the scroll position based on the drag distance
    },
    handleScrollbarClick(event) {
      event.stopPropagation();

      const scrollbar = this.$refs.scrollbar;
      const scrollContainer = this.$refs.scrollContainer;
      const scrollContent = this.$refs.scrollContent;

      const clickPosition = event.clientX - scrollbar.getBoundingClientRect().left;
      const scrollPercentage = clickPosition / scrollbar.offsetWidth;
      const maxScroll = scrollContent.scrollWidth - scrollContainer.offsetWidth;

      scrollContent.scrollLeft = scrollPercentage * maxScroll; // Update the scroll position based on the scrollbar click position
    },
    startScroll(event) {
      event.preventDefault();
      this.isScrolling = true;
      this.scrollX = event.pageX;

      window.addEventListener('mousemove', this.handleMouseMove);
      window.addEventListener('mouseup', this.stopScroll);
    },
    handleMouseMove(event) {
      if (this.isScrolling) {
        this.isMoved = true;
        const delta = this.scrollX - event.pageX;
        this.scrollX = event.pageX;
        this.$refs.scrollContent.scrollLeft += delta; // Update the scroll position based on the mouse movement
      }
    },
    stopScroll() {
      if (!this.isMoved) {
        this.isScrolling = false;
      } else {
        setTimeout(() => {
          this.isScrolling = false;
        }, 0);
      }
      this.isMoved = false;
      window.removeEventListener('mousemove', this.handleMouseMove);
      window.removeEventListener('mouseup', this.stopScroll);
    },
    checkedMove(event) {
      if (this.isScrolling) {
        event.stopPropagation();
        event.preventDefault();
      }
      this.isMoved = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.custom-scrollbar {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .custom-scrollbar__content {
    display: flex;
    height: 100%;
    overflow: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .custom-scrollbar__scrollbar {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 6px;
    background-color: #f0f0f0;
    opacity: 0.8;
    border-radius: 4px;
    cursor: pointer;
    transition: opacity 0.2s;
    user-select: none;

    &:hover {
      opacity: 1;
    }
  }

  .custom-scrollbar__scroll-thumb {
    position: absolute;
    top: 0;
    height: 6px;
    background-color: $main-color;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.2s;

    &:hover {
      opacity: 0.7;
    }
  }
}
</style>
