<template>
  <span
    :key="`uc-checkbox-${name}`"
    class="uc-checkbox-container"
    :class="{ 'uc-checkbox-container_disabled': disabled }"
    @click="clickHandler()"
  >
    <slot name="before-checkbox"></slot>
    <input v-model="isChecked" :disabled="disabled" type="checkbox" />
    <span class="uc-checkbox" />
    <slot></slot>
  </span>
</template>

<script>
export default {
  name: 'UcCheckbox',
  props: {
    value: {
      type: Array,
      default: () => []
    },
    name: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isChecked: false
    };
  },
  watch: {
    value: {
      handler() {
        this.isChecked = (this.value || []).includes(this.name);
      },
      immediate: true
    }
  },
  methods: {
    clickHandler() {
      if (this.disabled) {
        return null;
      }

      this.isChecked = !this.isChecked;
      let data = [...this.value];
      if (this.isChecked) {
        data.push(this.name);
      } else {
        data = data.filter((val) => val !== this.name);
      }

      this.$emit('input', data);
    }
  }
};
</script>
