export default {
  computed: {
    wrapperWidth() {
      let width = '';

      if (this.width !== 'AUTO' || this.height !== 'AUTO') {
        width = this.width !== 'AUTO' ? this.width / 2 : this.height / 2;
      }

      return width;
    },
    wrapperHeight() {
      let height = '';
      if (this.width !== 'AUTO' || this.height !== 'AUTO') {
        height = this.height !== 'AUTO' ? this.height / 2 : this.width / 2;
      }

      return height;
    }
  },
  methods: {
    getImgUrl(img, folder) {
      if (!img) {
        return '';
      }

      const imgfolder = folder || this.folder;

      let url = '';

      if (process.env.RESIZE_IMG) {
        const dimensions = this.width || this.height ? `${this.width}x${this.height}/` : '';
        url = `${process.env.BASE_IMG_URL}/${imgfolder}/${dimensions}${img}`;
      }

      if (!process.env.RESIZE_IMG) {
        url = `${process.env.BASE_IMG_URL}/${imgfolder}/${img}`;
      }

      return url;
    }
  }
};
