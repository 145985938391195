<template>
  <nav class="breadcrumbs" :class="{ ma: IS_BBS }">
    <ul class="breadcrumbs__list">
      <li v-for="(item, index) in linkList" :key="`breadcrumbs-item-${index}`" class="breadcrumbs__item">
        <uc-link
          v-if="item.routeName"
          :to="localePath({ name: item.routeName, params: item.routeParams || {} })"
          class="breadcrumbs__item-link"
          :class="{ disabled: linkList.length === 1 ? false : index === linkList.length - 1 }"
          @click.native="$emit('changed')"
        >
          {{ $t(item.title) }}
        </uc-link>
        <a
          v-if="!item.routeName && item.route"
          class="breadcrumbs__item-link"
          :href="item.route"
          :class="{ disabled: linkList.length === 1 ? false : index === linkList.length - 1 }"
          >{{ $t(item.title) }}
        </a>
      </li>
    </ul>
  </nav>
</template>
<script>
import { IS_BBS } from 'u-utils';

export default {
  name: 'Breadcrumbs',
  // Props data for render links
  // uc-link => { routeName, routeParams, title }
  // a => { route,title }
  props: {
    links: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    IS_BBS: () => IS_BBS,
    linkList() {
      if (!this.links.length) {
        return [];
      }

      return [
        {
          routeName: 'main',
          title: IS_BBS ? 'MA' : 'Chicco'
        },
        ...this.links.filter((item) => item.title && item.title !== 'null')
      ];
    }
  }
};
</script>
<style lang="scss" scoped>
.breadcrumbs {
  display: block;

  &__list {
    list-style: none;
    padding: 0;
  }
  &__item {
    @include text(0.875em, $font-regular, $black);
    display: inline;

    & + .breadcrumbs__item::before {
      margin: 0 11px;
      display: inline-block;
      text-align: center;
      width: 3px;
      color: $black;
      content: '\203A';
    }

    &-link {
      display: inline;
      &::first-letter {
        text-transform: uppercase;
      }
      &:hover {
        color: $matisse;
      }
      &.disabled {
        pointer-events: none;
        color: $gray;
      }
    }
  }

  &.ma {
    @media (min-width: $screen-xxl) and (max-width: $screen-xxxxl) {
      max-width: 90vw;
    }

    .breadcrumbs__item {
      @include text(1em, $font-regular, $main-color);
      & + .breadcrumbs__item::before {
        color: $black;
        content: '\002F';
        margin: 0 8px;
      }

      &-link {
        text-decoration: underline;

        &.disabled {
          pointer-events: none;
          color: initial;
          text-decoration: none;
        }
      }
    }
  }
}
</style>
