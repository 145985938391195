<template>
  <main class="error-page">
    <div class="container">
      <uc-breadcrumbs :links="links" />
      <section v-if="error.statusCode === 404" role="404">
        <section class="not-found">
          <img class="not-found__img" src="https://static.chicco.com.ua/chicco-assets/svg/404.svg" alt="404" />
          <span class="not-found__title">
            {{ $t('Ой, похоже этой страницы не существует...') }}
            {{ $t('Но у нас есть много интересного для вас') }}
          </span>
          <div class="not-foud__actions">
            <uc-link :to="localePath({ name: 'promotion-page' })" class="uc-button uc-button-blue">
              {{ $t('Акции') }}
            </uc-link>
            <uc-link :to="localePath({ name: 'main' })" class="uc-button uc-button-blue">
              {{ $t('На главную') }}
            </uc-link>
          </div>
        </section>
      </section>
    </div>
  </main>
</template>

<script>
import { ScrollToTopMixin } from 'u-mixins';
import metaRu from '~/src/meta/ru/404Meta.json';
import metaUa from '~/src/meta/ua/404Meta.json';

export default {
  mixins: [ScrollToTopMixin],
  props: {
    error: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      links: [
        {
          route: '/',
          title: 'Страница не найдена'
        }
      ]
    };
  },
  head() {
    return this.$metaService.getImportedMetaByLang(metaRu, metaUa, this.$i18n.locale);
  },
  beforeMount() {
    this.scrollToTopMain();
  }
};
</script>

<style lang="scss">
@import '~/assets/scss/error-page/error-page.scss';
</style>
