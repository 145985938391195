<template>
  <div class="product-additional-info labels">
    <div :class="{ 'display-none': !isPromo }" class="card-plate card-plate_promotion">
      <span class="card-plate__title">{{ $t('Акция') }}</span>
    </div>
    <div :class="{ 'display-none': !isNew }" class="card-plate card-plate_new">
      <span class="card-plate__title">NEW</span>
    </div>
    <div :class="{ 'display-none': !isPreOrder }" class="card-plate card-plate_preorder">
      <span class="card-plate__title">{{ $t('Ожидается') }}</span>
    </div>
    <div :class="{ 'display-none': !discount || discount < 1 }" class="card-plate card-plate_discount">
      <span class="card-plate__title">{{ `-${discount}%` }}</span>
    </div>
    <!--    <div v-show="false" class="card-plate card-plate_top">-->
    <!--      <span class="card-plate__title">{{ $t('Топ') }}</span>-->
    <!--    </div>-->
  </div>
</template>

<script>
export default {
  props: {
    isPromo: {
      type: Boolean,
      default: false
    },
    isNew: {
      type: Boolean,
      default: false
    },
    isPreOrder: {
      type: Boolean,
      default: false
    },
    discount: {
      type: Number,
      default: 0
    }
  }
};
</script>

<style lang="scss">
@import '~/assets/scss/product-item/product-additional.scss';
</style>
