import { ContextServices, Injector } from 'u-services';
import GTMService from 'u-meta/lib/gtm/GTM.service.js';
import ESputnikService from 'u-meta/lib/Esputnik.service.js';

export default (ctx, inject) => {
  const injector = new Injector(ctx, inject);
  const dependencyList = [
    { service: GTMService, alias: 'gtm' },
    { service: ContextServices.LocalStorageService, alias: 'lstore' },
    { service: ESputnikService, alias: 'esputnik' }
  ];

  injector.injectDependencyList(dependencyList);
};
