<template>
  <!--TODO: Update with better ux datepicker-->
  <div class="date-line">
    <!--TODO: Allow configure rules while reuse for other pages-->
    <multiselect
      v-model="datePicker.day"
      :options="[...datePickerList.days].reverse()"
      :placeholder="$t('День')"
      name="day"
      open-direction="bottom"
      :allow-empty="false"
      :show-labels="false"
      :searchable="false"
    />
    <multiselect
      v-model="datePicker.month"
      class="date_month"
      :options="datePickerList.months"
      track-by="value"
      label="label"
      name="month"
      :placeholder="$t('Месяц')"
      open-direction="bottom"
      :allow-empty="false"
      :show-labels="false"
      :searchable="false"
    />
    <multiselect
      v-model="datePicker.year"
      :options="[...datePickerList.futureYears].reverse()"
      name="year"
      :placeholder="$t('Год')"
      open-direction="bottom"
      :allow-empty="false"
      :show-labels="false"
      :searchable="false"
    />
    <uc-input
      v-model="formattedDate"
      rules="today-or-after"
      name="datepicker"
      input-classes="hidden-field"
      no-lable
      lable="Дата"
    />
  </div>
</template>

<script>
import DatePicker from 'u-auth/lib/components/mixin/DatePicker.mixin.js';

export default {
  components: {
    Multiselect: () => import(/* webpackChunkName: "multiselect" */ 'vue-multiselect').then((res) => res.Multiselect)
  },
  mixins: [DatePicker],
  data() {
    return {
      formattedDate: ''
    };
  },
  watch: {
    datePicker: {
      handler() {
        this.formattedDate = this.getFromDatePicker();
        this.$emit('input', this.formattedDate);
      },
      deep: true
    }
  },
  mounted() {
    this.fillDatePicker(Date.now());
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
