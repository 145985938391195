<script>
export default {
  name: 'RenderHtml',
  props: {
    rootElement: {
      type: String,
      default: 'div'
    },
    html: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      tags: '',
      content: ''
    };
  },
  watch: {
    html: {
      handler() {
        const { text, tags } = this.$content.getTextWithTagsInfo(this.html, ['style']);
        this.tags = tags;
        // TODO: implement this import sanitize from 'sanitize-html';
        this.content = text;
      },
      immediate: true
    }
  },
  mounted() {
    if (!this.tags.length) {
      return null;
    }

    this.tags.forEach((item) => {
      if (this.$content.headTags.indexOf(item.tag) + 1) {
        const doc = new DOMParser().parseFromString(item.content, 'text/html');
        document.head.appendChild(doc.head.firstChild);
      }
    });
  },
  render(h) {
    return h({
      template: `<${this.rootElement}>${this.content}</${this.rootElement}>`
    });
  }
};
</script>
