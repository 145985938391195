import { userStore } from 'u-store';

const {
  state: userState = {},
  mutations: userMutations = {},
  getters: userGetters = {},
  actions: userActions = {}
} = userStore;

export const state = userState;

export const mutations = {
  ...userMutations
};

export const getters = {
  ...userGetters
};

export const actions = {
  ...userActions
};
