<template>
  <section>
    <div class="container">
      <div class="category-description">
        <input id="category-description-checkbox" v-model="checked" type="checkbox" />
        <uc-render-html class="category-description-content" :html="content" />
        <label
          for="category-description-checkbox"
          class="category-description-label"
          :class="{ 'display-none': !isSwitch }"
        >
          <span class="category-description-button">
            <span>{{ checked ? $t('Свернуть') : $t('Читать полностью') }}</span>
          </span>
        </label>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions } from 'vuex';

import { IS_BBS, IS_NEW_MA } from 'u-utils';

import UcRenderHtml from '../UcRenderHtml.vue';

export default {
  name: 'UcCatalogDescription',
  components: { UcRenderHtml },
  props: {
    searchParams: {
      type: Object,
      default: () => ({})
    },
    text: {
      type: String,
      default: ''
    },
    isCustomCatalog: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      content: this.text,
      checked: false,
      isSwitch: false
    };
  },
  watch: {
    searchParams: {
      handler() {
        // bbs not recreate component on search params change, chicco, ma recreate
        if (IS_BBS && !IS_NEW_MA) {
          this.getDescription();
        }
      },
      deep: true
    }
  },
  mounted() {
    if (!this.text) {
      this.getDescription();
    }
  },
  methods: {
    ...mapActions({
      getContentBlockBySlug: 'content/getContentBlockBySlug'
    }),
    async getDescription() {
      try {
        const params = this.isCustomCatalog
          ? this.$catalog.getParamsForCustomCatalog(this.searchParams, this.$route.params.slug)
          : this.searchParams;
        const catalogsDescriptionSlug = this.$content.getCatalogsDescriptionSlug(params);

        if (!catalogsDescriptionSlug) {
          this.content = '';
          this.isSwitch = false;
          return;
        }

        this.content =
          (await this.getContentBlockBySlug({
            slug: catalogsDescriptionSlug,
            lang: this.$i18n.locale
          })) || '';
        this.isSwitch = this.content.indexOf('switch') !== -1;
      } catch (error) {
        console.log(error);
        this.$sentry.captureException(error);
      }
    }
  }
};
</script>
