export const DefaultDeliveryMethods = [
  {
    type: 2,
    carrier: 0,
    iconImageUrl: 'car_60.svg',
    iconImageUrlSelected: '',
    price: 50,
    shortDescription: '1-2 дней',
    title: 'Доставка курьером по Киеву',
    changeable: true,
    slug: 'im-courier'
  },
  {
    type: 2,
    carrier: 1,
    iconImageUrl: 'meest_60.svg',
    iconImageUrlSelected: '',
    price: 50,
    shortDescription: '2-5 дней',
    title: 'Доставка курьером по Украине (Meest)',
    changeable: true,
    slug: 'meest-courier'
  },
  {
    type: 3,
    carrier: 0,
    iconImageUrl: 'pickup_60.svg',
    iconImageUrlSelected: '',
    price: 0,
    shortDescription: '30 минут - до 3-х дней',
    title: 'Самовывоз',
    slug: 'pick-and-collect'
  },
  {
    type: 1,
    carrier: 2,
    iconImageUrl: 'novaposhta_60.svg',
    iconImageUrlSelected: '',
    price: 50,
    shortDescription: '50 грн, 1-3 дней',
    title: 'Нова Пошта',
    slug: 'np-warehouse'
  },
  {
    type: 2,
    carrier: 2,
    iconImageUrl: '4134345bca36da81588f4cd838622004.svg',
    iconImageUrlSelected: '2b2d11bbd0264721e98e50a2b96705db.svg',
    price: 99,
    shortDescription: '1-2 дня',
    slug: 'np-courier',
    title: 'Курьер "Нова Пошта"'
  }
];
