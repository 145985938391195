import locale77fcde9e from '../../locales/ru.js'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"fallbackLocale":"ru","silentFallbackWarn":true,"silentTranslationWarn":true},
  vueI18nLoader: false,
  locales: [{"code":"ua","iso":"uk","file":"ua.js","hreflang":"uk","htmlAttrsLang":"uk"},{"code":"ru","iso":"ru-RU","file":"ru.js","hreflang":"ru","htmlAttrsLang":"ru"}],
  defaultLocale: "ru",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix",
  lazy: true,
  langDir: "/var/app/current/locales",
  rootRedirect: null,
  detectBrowserLanguage: {"alwaysRedirect":true,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_redirected","cookieSecure":false,"fallbackLocale":"ru","onlyOnNoPrefix":false,"onlyOnRoot":true,"useCookie":true},
  differentDomains: false,
  seo: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncLocale":false,"syncMessages":false,"syncRouteParams":true},
  parsePages: false,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  beforeLanguageSwitch: () => null,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"code":"ua","iso":"uk","file":"ua.js","hreflang":"uk","htmlAttrsLang":"uk"},{"code":"ru","iso":"ru-RU","file":"ru.js","hreflang":"ru","htmlAttrsLang":"ru"}],
  localeCodes: ["ua","ru"],
}

export const localeMessages = {
  'ru.js': () => Promise.resolve(locale77fcde9e),
  'ua.js': () => import('../../locales/ua.js' /* webpackChunkName: "lang-ua.js" */),
}
