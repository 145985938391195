<template>
  <div class="auth-loading-spinner-wrapper">
    <loading-spinner />
  </div>
</template>

<script>
import LoadingSpinner from './LoadingSpinner.vue';

export default {
  name: 'AuthLoadingSpinner',
  components: { LoadingSpinner }
};
</script>

<style lang="scss">
.auth-loading-spinner-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  overflow: hidden;

  @media (max-width: 767px) {
    margin-top: 20px;
  }
}
</style>
