<template>
  <ValidationObserver v-slot="{ validate, errors }" ref="observer" slim :disabled="disabled">
    <form :class="formClass" novalidate @submit.prevent="submit">
      <slot :validate="validate" :erros="errors"></slot>
    </form>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver } from 'vee-validate';

export default {
  name: 'UcForm',
  components: { ValidationObserver },
  props: {
    handler: {
      type: Function,
      required: true
    },
    formClass: {
      type: [String, Object],
      default: ''
    },
    scrollToError: {
      type: Boolean,
      default: true
    },
    disabled: {
      //https://vee-validate.logaretm.com/v3/api/validation-observer.html#props
      type: Boolean,
      default: false
    }
  },
  computed: {
    firstFieldKey() {
      return Object.keys(this.$refs.observer.fields)[0];
    }
  },
  methods: {
    async submit() {
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        this.handler();
      } else {
        this.$emit('invalid');
        this.scrollViewToError();
      }
    },
    setErrors(errors) {
      this.$refs.observer.setErrors(errors);
    },
    resetErrors() {
      this.$refs.observer.reset();
    },
    scrollViewToError() {
      if (!this.scrollToError) {
        return;
      }
      const firstErrorField = this.$el.querySelector('.uc-invalid');
      if (!firstErrorField) {
        return;
      }
      firstErrorField.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    }
  }
};
</script>
