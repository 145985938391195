<template>
  <div v-if="img" class="image-wrapper">
    <nuxt-img
      v-if="isSlide"
      :src="getImgUrl(img)"
      :loading="lazy ? 'lazy' : 'eager'"
      :width="width"
      :height="height"
      :alt="alt"
      :title="title"
      :class="[className]"
    />

    <client-only v-else>
      <nuxt-img
        :src="getImgUrl(img)"
        :loading="lazy ? 'lazy' : 'eager'"
        :width="width"
        :height="height"
        :alt="alt"
        :title="title"
        :class="[className]"
      />
    </client-only>

    <noscript inline-template>
      <img :src="getImgUrl(img)" :alt="alt" :title="title" :class="[className]" />
    </noscript>
  </div>
</template>

<script>
// TODO: Test this noscript
export default {
  name: 'UcImage',
  props: {
    img: {
      type: String,
      default: ''
    },
    alt: {
      type: String,
      default: 'image'
    },
    title: {
      type: String,
      default: 'image'
    },
    width: {
      type: String,
      default: 'AUTO'
    },
    height: {
      type: String,
      default: 'AUTO'
    },
    folder: {
      type: String,
      default: 'images'
    },
    className: {
      type: String,
      default: ''
    },
    isSlide: {
      type: Boolean,
      default: false
    },
    lazy: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    getImgUrl(img) {
      if (!img) {
        return '';
      }

      let url = '';
      const gifСheck = img.lastIndexOf('.gif') + 1;

      if (process.env.RESIZE_IMG && !gifСheck) {
        const dimensions = this.width || this.height ? `${this.width}x${this.height}/` : '';
        url = `${process.env.BASE_IMG_URL}/${this.folder}/${dimensions}${img}`;
      }

      if (!process.env.RESIZE_IMG || (process.env.RESIZE_IMG && gifСheck)) {
        url = `${process.env.BASE_IMG_URL}/${this.folder}/${img}`;
      }
      return url;
    }
  }
};
</script>

<style lang="scss" scoped>
.hidden {
  visibility: hidden;
  position: absolute;
}

.image-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  img {
    height: auto;
  }
}
</style>
