import { bonusStore } from 'u-store';

const {
  state: bonusState = {},
  mutations: bonusMutations = {},
  getters: bonusGetters = {},
  actions: bonusActions = {}
} = bonusStore;

export const state = bonusState;

export const mutations = {
  ...bonusMutations
};

export const getters = {
  ...bonusGetters
};

export const actions = {
  ...bonusActions
};
