<template>
  <uc-link :to="slide.link || ''" class="promotions-slider-item">
    <img
      :src="getImgUrl(slide.img, 'slider-image/AUTOxAUTO')"
      :alt="slide.alt"
      :title="slide.title"
      @click="sendToGtmSelected"
    />
  </uc-link>
</template>

<script>
import ImageMixin from '~/src/mixins/Image.mixin.js';

export default {
  mixins: [ImageMixin],
  props: {
    slide: {
      type: Object,
      default: () => ({})
    },
    position: {
      type: Number,
      required: true
    }
  },
  computed: {
    gtmPayload() {
      if (!this.slide) {
        return null;
      }
      return {
        promotion_id: this.slide.promotion_id,
        promotion_name: this.slide.promotion_name,
        creative_slot: this.position + 1
      };
    }
  },
  methods: {
    sendToGtmSelected() {
      this.$gtm.setPromotionAction(this.gtmPayload, this.$gtm.constructor.PROMOTION_SELECT_KEY);
    }
  }
};
</script>
