<template>
  <uc-button :type="type" :disabled="disabled" @click="callback($event)">
    <uc-loader v-if="loading" />
    <slot :loading="loading">{{ $t('Сохранить') }}</slot>
  </uc-button>
</template>

<script>
export default {
  name: 'LoaderButton',
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'button'
    }
  },
  methods: {
    callback(e) {
      this.$emit('click', e);
    }
  }
};
</script>
