<template>
  <div class="pagination-service">
    <button
      v-if="value < totalPages && isDisplayMore"
      type="button"
      class="show-more"
      :disabled="loading"
      @click="showMore"
    >
      <span class="show-more-title">{{ $t('Показать еще') }}</span>
    </button>
    <ul class="pagination-list pagination">
      <li v-if="value === 1" class="pagination-item pagination-item--active">
        <span class="pagination-link pagination-link--active">
          {{ 1 }}
        </span>
      </li>

      <li v-else class="pagination-item">
        <a :href="baseLink" aria-label="Go to first page" @click="onClickPage(1, $event)">
          <span class="pagination-link">
            {{ 1 }}
          </span>
        </a>
      </li>

      <li v-if="value > 4 && totalPages > 6" class="pagination-item pagination-link--disable">
        <span class="pagination-link pagination-link--disable">...</span>
      </li>

      <template v-for="(page, index) in pages">
        <li
          v-if="value === page.name"
          :key="`pagination-link-${index}`"
          class="pagination-item pagination-item--active"
        >
          <span class="pagination-link pagination-link--active">
            {{ page.name }}
          </span>
        </li>

        <li v-else :key="`pagination-link-${index}`" class="pagination-item">
          <a
            :href="getPageLink(page.name)"
            :class="{ active: page.active }"
            :aria-label="`Go to page number ${page.name}`"
            @click="onClickPage(page.name, $event)"
          >
            <span class="pagination-link">
              {{ page.name }}
            </span>
          </a>
        </li>
      </template>

      <li v-if="value < totalPages - 3 && totalPages > 6" class="pagination-item pagination-link--disable">
        <span class="pagination-link pagination-link--disable">...</span>
      </li>

      <li v-if="value === totalPages && totalPages > 1" class="pagination-item pagination-item--active">
        <span class="pagination-link pagination-link--active">
          {{ totalPages }}
        </span>
      </li>

      <li v-if="value !== totalPages && totalPages > 1" class="pagination-item">
        <a :href="getPageLink(totalPages)" aria-label="Go to last page" @click="onClickPage(totalPages, $event)">
          <span class="pagination-link">
            {{ totalPages }}
          </span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    value: {
      type: Number,
      required: false,
      default: 0
    },
    maxVisibleButtons: {
      type: Number,
      required: false,
      default: 3
    },
    totalPages: {
      type: Number,
      required: true
    },
    pageLink: {
      type: String,
      required: false,
      default: ''
    },
    baseLink: {
      type: String,
      required: false,
      default: ''
    },
    isDisplayMore: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    startPage() {
      if (this.totalPages <= 6) {
        return 2;
      }
      if (this.value <= 4) {
        return 2;
      }
      if (this.value === this.totalPages) {
        return this.totalPages - 2;
      }
      if (this.value === this.totalPages - 1) {
        return this.totalPages - 2;
      }
      if (this.value === this.totalPages - 2) {
        return this.totalPages - 3;
      }
      if (this.value === this.totalPages - 3) {
        return this.totalPages - 4;
      }
      return this.value - 1;
    },
    endPage() {
      if (this.totalPages <= 6) {
        return this.totalPages - 1;
      }
      if (this.value === 1) {
        return 3;
      }
      if (this.value === 2) {
        return this.startPage + 1;
      }
      if (this.value === 3) {
        return this.startPage + 2;
      }
      if (this.value === 4) {
        return this.startPage + (this.value + 3 === this.totalPages ? 4 : 3);
      }
      if (this.value > this.totalPages - 4) {
        return this.totalPages - 1;
      }
      return Math.min(this.startPage + this.maxVisibleButtons - 1, this.totalPages - 1);
    },
    pages() {
      const range = [];

      for (let i = this.startPage; i <= this.endPage; i++) {
        range.push({
          name: i,
          active: i === this.value
        });
      }

      return range;
    }
  },
  methods: {
    onClickPage(page, event) {
      event.preventDefault();
      this.$emit('input', page);
    },
    getPageLink(page) {
      if (!this.pageLink) {
        return '';
      }

      return this.pageLink.replace('%page%', page);
    },
    showMore() {
      this.$emit('show-more');
    }
  }
};
</script>
