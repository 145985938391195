<template>
  <div v-if="content" class="folder-category-banner">
    <uc-render-html v-if="isHTML" :html="content" />
    <a v-else :href="linkWithLocale" @click.prevent="handleClick">
      <img
        class="page-block__img"
        :class="desktopImgClasses"
        :src="getImageWithBase(content.imgDesktopUrl)"
        :alt="content.alt"
      />
      <img
        class="page-block__img"
        :class="mobileImgClasses"
        :src="getImageWithBase(content.imgMobileUrl)"
        :alt="content.alt"
      />
    </a>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import UcRenderHtml from '../UcRenderHtml.vue';

export default {
  name: 'UcCatalogBanner',
  components: { UcRenderHtml },
  props: {
    searchParams: {
      type: Object,
      default: () => ({})
    },
    contentFormatted: {
      type: [String, Object],
      default: ''
    },
    desktopImgClasses: {
      type: String,
      default: 'h-sm'
    },
    mobileImgClasses: {
      type: String,
      default: 'h-md h-lg h-xl'
    },
    isCustomCatalog: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      content: this.contentFormatted
    };
  },
  computed: {
    isHTML() {
      return typeof this.content === 'string';
    },
    gtmPayload() {
      if (!this.content) {
        return null;
      }
      return {
        promotion_id: this.content.promotion_id,
        promotion_name: this.content.promotion_name,
        creative_slot: this.content.creative_slot
      };
    },
    linkWithLocale() {
      return this.localePath({ path: this.content.link });
    }
  },
  watch: {
    searchParams: {
      handler() {
        this.getBanner();
      },
      deep: true
    }
  },
  async mounted() {
    if (!this.contentFormatted) {
      await this.getBanner();
    }
    this.$gtm.setPromotionAction(this.gtmPayload, this.$gtm.constructor.PROMOTION_VIEW_KEY);
  },
  methods: {
    ...mapActions({
      getContentBlockBySlug: 'content/getContentBlockBySlug'
    }),
    getImageWithBase(src) {
      return `${process.env.BASE_IMG_URL}${src}`;
    },
    async getBanner() {
      try {
        const params = this.isCustomCatalog
          ? this.$catalog.getParamsForCustomCatalog(this.searchParams, this.$route.params.slug)
          : this.searchParams;
        const catalogsBannerSlug = this.$content.getCatalogsBannerSlug(params);
        if (!catalogsBannerSlug) {
          this.content = '';
          return;
        }
        this.content =
          (await this.getContentBlockBySlug({
            slug: catalogsBannerSlug,
            lang: this.$i18n.locale
          })) || null;
      } catch (error) {
        console.log(error);
        this.$sentry?.captureException(error);
      }
    },
    handleClick() {
      this.$gtm.setPromotionAction(this.gtmPayload, this.$gtm.constructor.PROMOTION_SELECT_KEY);
      if (this.linkWithLocale === window.location.pathname) {
        return;
      }

      if (this.linkWithLocale === this.$route.fullPath) {
        /* $route outdated, router.push will not work
          (due to u-catalog/lib/filters/mixin/Filter.mixin.js, method changeUrl),
          need to update filters, sort manually
        */
        const { filters, sort } = this.$catalogUrlService.parseUrl(this.linkWithLocale);
        this.$emit('update-manual', { filters, sort });

        return;
      } else {
        this.$router.push(this.linkWithLocale);
      }
    }
  }
};
</script>
