<template>
  <section class="product-images">
    <product-image
      :is-new="isNew"
      :is-pre-order="isPreOrder"
      :discount="discount"
      :rotate-images="collectedRotateImages"
      :curent-item="curentItem"
    />

    <client-only>
      <image-select
        :slider-config="sliderConfig"
        :videos="videos"
        :images="images"
        :rotate-images="collectedRotateImages"
        @selected-item="select"
      />
    </client-only>
  </section>
</template>

<script>
import ProductImage from './ProductImage.vue';
import ImageSelect from './ImageSelect.vue';
import ScreenWidth from './../../mixin/ScreenWidth.js';

import { productModels } from 'u-models';
const MainImageModel = productModels.mainImage;
import * as catalogMutation from 'u-catalog/lib/store/mutation-types.js';

export default {
  components: {
    ProductImage,
    ImageSelect
  },
  mixins: [ScreenWidth],
  props: {
    sliderConfig: {
      type: Object,
      default: () => ({})
    },
    images: {
      type: Array,
      default: () => []
    },
    rotateImages: {
      type: Array,
      default: () => []
    },
    videos: {
      type: Array,
      default: () => []
    },
    isNew: {
      type: Boolean,
      default: false
    },
    isPreOrder: {
      type: Boolean,
      default: false
    },
    discount: {
      type: Number,
      default: null
    }
  },
  data: () => {
    return {
      curentItem: {}
    };
  },
  computed: {
    collectedRotateImages() {
      return this.rotateImages.map((item) => item.link);
    }
  },
  watch: {
    images: {
      handler: function (images) {
        const [image] = images;
        if (image) {
          const mainImageModel = new MainImageModel();
          this.select(mainImageModel.setImage(image));
        }
      },
      immediate: true
    }
  },
  mounted() {
    this.$eventBus.$on('product:slider:to_rotate', this.setToRotate);
    this.$nextTick(() => {
      this.setDefaultItem();
    });
  },
  beforeDestroy() {
    this.$eventBus.$off('product:slider:to_rotate', this.setToRotate);
    this.$store.commit(`catalog/${catalogMutation.SET_ROTATE_IMG}`, false);
  },
  methods: {
    select(item) {
      this.curentItem = item;
    },
    setDefaultItem() {
      try {
        const [video] = this.videos;
        const [image] = this.images;
        const mainImageModel = new MainImageModel();

        if (video) {
          this.select(mainImageModel.setVideo(video));
        } else if (this.rotateImages.length) {
          this.select(mainImageModel.setRotateImages());
        } else {
          this.select(mainImageModel.setImage(image));
        }
      } catch (error) {
        console.error('Set Default Slider Item Error', error);
      }
    },
    setToRotate() {
      const mainImageModel = new MainImageModel();
      this.select(mainImageModel.setRotateImages());
    }
  }
};
</script>
