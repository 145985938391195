<template>
  <div class="product">
    <div class="product__head">
      <uc-link
        :to="localePath({ name: 'product-detail-page', params: { id: product.slug } })"
        @click.native="clickHandle"
      >
        <labels
          :is-promo="productItemModel.chosenNomenclature.isPromotional"
          :is-new="productItemModel.chosenNomenclature.isNew"
          :is-pre-order="productItemModel.chosenNomenclature.isPreOrder"
          :discount="isDiscount() ? productItemModel.chosenNomenclature.discountRate : 0"
        />
        <uc-image
          :img="productItemModel.image.link"
          :alt="productItemModel.image.alt"
          :title="productItemModel.image.title"
          :width="setimgResizeWidth()"
          class-name="product__head-img"
          :class="{ 'not-available': productItemModel.chosenNomenclature.totalAmount <= 0 || !canSetBasket }"
        />
      </uc-link>
      <client-only>
        <catalog-wish-list-widget :product="product" :nomenclature-id="productItemModel.chosenNomenclature.id" />
      </client-only>
    </div>
    <div v-if="!productItemModel.variants.isActive" class="product__colors">
      <div v-if="productItemModel.colors.length > 1">
        <client-only>
          <uc-color-list
            class="product__colors-wrapper"
            item-class-name="color"
            :colors="productItemModel.colors"
            :active-color="activeColorSlug"
            :visible-colors-count="visibleColorsCount"
            @change="setColor($event), clickHandle()"
          />
        </client-only>
      </div>
    </div>
    <UcVariants
      v-if="withVariants && productItemModel.variants.isActive"
      :with-title="false"
      :variants="productItemModel.variants"
      :active-slug="productItemModel.slug"
    />
    <uc-link
      :to="localePath({ name: 'product-detail-page', params: { id: productItemModel.slug } })"
      class="product__name"
      @click.native="clickHandle"
    >
      <uc-render-html root-element="span" :html="productItemModel.title" />
    </uc-link>
    <div class="product__rating">
      <client-only>
        <uc-link
          v-if="itemRating"
          :to="localePath({ name: 'product-detail-page', params: { id: productItemModel.slug } })"
          class="product__rating-link"
          @click.native="linkToTheAnchor()"
        >
          <uc-star-rating
            :rating="itemRating.rating"
            :read-only="true"
            :show-rating="false"
            :star-size="16"
            :round-start-rating="false"
            :padding="3"
            active-color="#ffba09"
            inactive-color="#8e8e8e"
          />
          <span class="product__rating-text">{{ itemRating.count }}</span>
        </uc-link>
      </client-only>
      <uc-link
        v-if="!itemRating"
        :to="localePath({ name: 'product-detail-page', params: { id: productItemModel.slug } })"
        class="product__rating-link"
        @click.native="linkToTheAnchor()"
      >
        <span class="product__rating-text">{{ $t('Оставить отзыв') }}</span>
      </uc-link>
    </div>
    <div class="product__price">
      <div class="product__price-block">
        <div
          v-if="canSetBasket && productItemModel.chosenNomenclature.totalAmount > 0"
          class="product__price-available"
        >
          <span
            v-if="
              productItemModel.chosenNomenclature.probablyPriceRecommended >
              productItemModel.chosenNomenclature.probablyPriceSelling
            "
            class="product__price-old"
            >{{ productItemModel.chosenNomenclature.probablyPriceRecommended | currency }}</span
          >
          <span class="product__price-current">{{
            productItemModel.chosenNomenclature.probablyPriceSelling | currency
          }}</span>
          <span
            v-if="
              productItemModel.chosenNomenclature.probablyPriceRecommended &&
              productItemModel.chosenNomenclature.probablyPriceSelling &&
              productItemModel.chosenNomenclature.probablyPriceRecommended >
                productItemModel.chosenNomenclature.probablyPriceSelling
            "
            class="product__price-bonus"
          >
            {{ $t('выгода') }}
            {{
              productItemModel.chosenNomenclature.probablyPriceRecommended -
              productItemModel.chosenNomenclature.probablyPriceSelling
            }}
            грн
          </span>
        </div>
        <div
          v-if="(!canSetBasket || productItemModel.chosenNomenclature.totalAmount <= 0) && !expectationDate"
          class="product__price-not-available"
        >
          <span class="product__price-not-available-text">{{ $t('Нет в наличии') }}</span>
        </div>
        <div
          v-if="(!canSetBasket || productItemModel.chosenNomenclature.totalAmount <= 0) && expectationDate"
          class="product__price-not-available"
        >
          <span class="product__price-not-available-text">{{ $t('Ожидается') }}: {{ expectationDate }}</span>
        </div>
      </div>
      <div
        v-if="canSetBasket && productItemModel.chosenNomenclature.totalAmount && !isCertificate"
        class="product__price-buttons"
      >
        <uc-button
          class="uc-button-blue-and-white button-buy"
          @click="addToBasket(productItemModel.chosenNomenclature)"
          >{{ $t('В корзину') }}</uc-button
        >
        <div class="button-buy--mobile" @click="addToBasket(productItemModel.chosenNomenclature)">
          <img src="https://static.chicco.com.ua/chicco-assets/svg/shopping-cart.svg" alt="cart" />
        </div>
      </div>
    </div>
    <div v-if="showDetails" class="product__details">
      <div class="details-info">
        Baby Hug 4 в 1 — это универсальное решение, разработанное Chicco для ребенка с момента рождения до 36 месяцев,
        которое поможет родителям в повседневной жизни. Baby Hug 4 в 1 — это универсальное решение, разработанное Chicco
        для ребенка с момента рождения до 36 месяцев, которое поможет родителям в повседневной жизни.
      </div>
    </div>
  </div>
</template>

<script>
import { productModels } from 'u-models';
import { AddToBasketMixin, ScreenWidthMixin } from 'u-mixins';
import * as CatalogMutation from 'u-catalog/lib/store/mutation-types.js';
import ProductItemModel from 'u-catalog/lib/models/ProductItem.model.js';
import { CERTIFICATE_SLUG } from 'u-utils/module/cons.js';

import Labels from '~/src/components/general/productItem/Labels.vue';
import CatalogWishListWidget from '~/src/components/wish-list/CatalogWidget.vue';

const ProductModel = productModels.product;

export default {
  components: {
    Labels,
    CatalogWishListWidget
  },
  mixins: [AddToBasketMixin, ScreenWidthMixin],
  props: {
    product: {
      type: Object,
      default: () => ({})
    },
    category: {
      type: Object,
      default: () => ({})
    },
    showDetails: {
      type: Boolean,
      default: true
    },
    rating: {
      type: Array,
      default: () => []
    },
    position: {
      type: Number,
      default: null
    },
    gtmPayload: {
      type: Object,
      default: () => {}
    },
    withVariants: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      productItem: {},
      activeColorSlug: '',
      canSetBasket: true,
      expectationDate: false
    };
  },
  computed: {
    itemRating() {
      return this.rating.find((rating) => rating.productId === this.product.id);
    },
    visibleColorsCount() {
      if (this.isScreenSmallMobile) {
        return 3;
      }
      return 4;
    },
    isCertificate() {
      return this.product.slug === CERTIFICATE_SLUG;
    }
  },
  watch: {
    product: {
      handler(val) {
        this.productItemModel = new ProductItemModel(val);
      },
      immediate: false
    },
    '$store.state.basket.basket': {
      handler() {
        this.checkCanSetBasket();
      },
      immediate: false
    }
  },
  created() {
    this.productItemModel = new ProductItemModel(this.product);
    this.setExpectationDate();
  },
  mounted() {
    this.setColor({ color: this.productItemModel.colors[0]?.color, slug: this.productItemModel.colors[0]?.slug });
  },
  methods: {
    isDiscount() {
      const { categoryConfig } = this.$store.state.catalog;

      const folderCategory = this.productItemModel.folderCategorySlug;
      const category = this.productItemModel.categorySlug;

      if (!folderCategory || !category) {
        return false;
      }

      return categoryConfig.some((item) => {
        return (
          (item.slug === folderCategory && item.showDiscountLabel) ||
          item.childList.some((childItem) => {
            return childItem.slug === category && childItem.showDiscountLabel;
          })
        );
      });
    },
    linkToTheAnchor() {
      this.$store.commit(`catalog/${CatalogMutation.SET_ANCHOR}`, 'tabReviews');
      this.clickHandle();
    },
    setColor({ color, slug }) {
      this.productItemModel.setChosenNomenclature(color);
      this.activeColorSlug = slug;
      this.checkCanSetBasket();
      this.setExpectationDate();
    },
    addToBasket(chosenNomenclature, count = 1) {
      const productModel = new ProductModel(this.product);
      const nomenclature = productModel.getChosenNomenclature(
        chosenNomenclature.colorPresentationSlug,
        chosenNomenclature.sizePresentationSlug
      );

      if (!this.productItemModel.sizes.length) {
        this.addItemToBasket(nomenclature, count);
        this.isScreenTabletAndLess
          ? this.$eventBus.$emit('basket_add_notification:display', { ...nomenclature, count })
          : this.$eventBus.$emit('basket_modal:display', { isGTMSendEvent: false });
      } else {
        this.$eventBus.$emit('modals:add_size_to_basket:open', {
          product: this.product,
          nomenclature: nomenclature,
          sizes: this.productItemModel.sizes,
          color: chosenNomenclature.colorPresentationSlug,
          count: count
        });
      }
    },
    setimgResizeWidth() {
      if (this.isScreenMobile) {
        return `280`;
      } else if (global.innerWidth > 768 && global.innerWidth < 1224) {
        return `300`;
      } else return `448`;
    },
    checkCanSetBasket() {
      if (this.productItemModel && this.productItemModel.chosenNomenclature) {
        this.canSetBasket = this.$basket.canAddToBasketCheck(this.productItemModel.chosenNomenclature.id || '');
      }
    },
    setExpectationDate() {
      try {
        this.expectationDate = false;
        const chosenNomenclature = this.productItemModel.chosenNomenclature;
        if (!chosenNomenclature) {
          return false;
        }
        const currentDate = new Date();
        const expectationDate = new Date(chosenNomenclature.expectationDate);
        if (expectationDate < currentDate) {
          return false;
        }
        this.expectationDate = chosenNomenclature.expectationDate;
      } catch (error) {
        console.error('Set expectationDate Error: ', error);
        return false;
      }
    },
    clickHandle() {
      this.$gtm.setProductClickDataLayer(
        this.product,
        this.productItemModel.chosenNomenclature,
        this.position,
        this.$route,
        this.gtmPayload
      );
    }
  }
};
</script>

<style lang="scss">
@import '~@/assets/scss/product-item/product-item.scss';
</style>
