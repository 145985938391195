<template>
  <div class="img-wrapper">
    <labels :is-new="isNew" :is-pre-order="isPreOrder" :discount="discount" />
    <div class="img-wrapper-zoom-ico" @click="displayLargeImageModal()">
      <img src="https://static.chicco.com.ua/assets/svg/detail/loupe.svg" alt="zoom" />
    </div>

    <div v-if="curentItem.isImage" class="big-imag" @click="displayLargeImageModal()">
      <div class="big-imag__link popup-with-zoom-anim">
        <uc-image :img="curentItem.source" :alt="curentItem.alt" :title="curentItem.title" :width="`1000`" />
      </div>
    </div>
    <client-only>
      <div v-if="curentItem.isVideo" ref="iframeVideo" class="slider-content-video">
        <iframe :src="curentItem.source" width="530" height="430" allowfullscreen="allowfullscreen" />
      </div>
    </client-only>

    <div v-if="curentItem.isRotateImage" class="big-imag">
      <rotate-image :images="rotateImages" width="1000" />
    </div>
  </div>
</template>

<script>
import Labels from './Labels.vue';
import RotateImage from './RotateImage.vue';

export default {
  components: {
    Labels,
    RotateImage
  },
  props: {
    isNew: {
      type: Boolean,
      default: false
    },
    isPreOrder: {
      type: Boolean,
      default: false
    },
    discount: {
      type: Number,
      default: null
    },
    rotateImages: {
      type: Array,
      default: () => []
    },
    curentItem: {
      type: Object,
      required: true
    }
  },
  methods: {
    displayLargeImageModal() {
      this.$eventBus.$emit('product:large_image:display');
    }
  }
};
</script>
