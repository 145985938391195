<template>
  <div ref="shopsList" class="col col-sm-12 col-xl-4 shops-list">
    <div
      v-for="(warehouseCityList, index) in sortedWarehouseList"
      :key="`shops-city-warehouse-list-${index}`"
      class="city"
    >
      <h2 v-if="warehouseCityList.warehouseList.length" class="title-city">
        {{ warehouseCityList.warehouseList[0].city }}
      </h2>
      <div class="city-address shops-list-chicco">
        <shop
          v-for="(item, shopIndex) in warehouseCityList.warehouseList"
          :key="`warehouse-city-list-${shopIndex}-${item.slug}`"
          :ref="`shop_${item.slug}`"
          :shop="item"
          :active="activeShop === item.slug"
          @click="clickShop"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Shop from './Shop.vue';
import { TranslatorMixin, ShopListMixin, ScrollToTopMixin } from 'u-mixins';

export default {
  components: { Shop },
  mixins: [TranslatorMixin, ShopListMixin, ScrollToTopMixin],
  props: {
    activeShop: {
      type: String,
      default: ''
    },
    warehouseList: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    sortedWarehouseList() {
      const sortedWarehouseList = [...this.warehouseList];

      sortedWarehouseList.sort((a, b) => {
        const cityA = a.warehouseList[0]?.city?.toUpperCase() || '';
        const cityB = b.warehouseList[0]?.city?.toUpperCase() || '';

        if (cityA < cityB) {
          return -1;
        }
        if (cityA > cityB) {
          return 1;
        }
        return 0;
      });

      return sortedWarehouseList;
    }
  },
  methods: {
    clickShop(shop) {
      this.setActiveShopSlug(shop);
      this.scrollToTopMain();
    }
  }
};
</script>
