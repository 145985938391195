// TEST DATA
export default {
  дней: 'дней | день | дня | дней',
  часов: 'часов | час | часа | часов',
  минут: 'минут | минута | минуты | минут',
  секунд: 'секунд | секунда | секунды | секунд',
  товаров: '0 товаров | {n} товар | {n} товара | {n} товаров',
  отзывов: '0 отзывов | {n} отзыв | {n} отзыва | {n} отзывов',
  ответ: '0 ответов | {n} ответ | {n} ответа | {n} ответов',
  бонусов: 'бонусов | бонус | бонуса | бонусов',
  выбрано: 'выбрано | выбран | выбрано | выбрано',
  скидок: 'скидок | скидка | скидки | скидок',
  // MAIN
  Покупателю: 'Покупателю',
  'О компании': 'О компании',
  'Наши магазины': 'Наши магазины',
  'Доставка и оплата': 'Доставка и оплата',
  'Гарантия и сервис': 'Гарантия и сервис',
  'Бонусная программа': 'Бонусная программа',
  'Акции и распродажи': 'Акции и распродажи',
  'Пакунок малюка': 'Пакунок малюка',
  'Подарочные сертификаты': 'Подарочные сертификаты',
  Магазины: 'Магазины',
  'Магазины на карте': 'Магазины на карте',
  'Бонусная программа babycard': 'Бонусная программа babycard',
  'MA.COM.UA – генеральный партнер': 'MA.COM.UA – генеральный партнер',
  'Обратная связь': 'Обратная связь',
  'Возврат и обмен': 'Возврат и обмен',
  'Сервис и гарантия': 'Сервис и гарантия',
  'Вопросы и ответы': 'Вопросы и ответы',
  'О нас': 'О нас',
  Вакансии: 'Вакансии',
  'Социальные проекты': 'Социальные проекты',
  Новости: 'Новости',
  'Новости компании': 'Новости компании',
  'Про Chicco': 'Про Chicco',
  'Школа мам': 'Школа мам',
  'Это интересно!': 'Это интересно!',
  Блог: 'Блог',
  Корзина: 'Корзина',
  Экономия: 'Экономия',
  'В корзину': 'В корзину',
  'Нет в наличии': 'Нет в наличии',
  'Оформить заказ': 'Оформить заказ',
  'Общая сумма': 'Общая сумма',
  Избранное: 'Избранное',
  'Каталог товаров': 'Каталог товаров',
  Бренды: 'Бренды',
  'Мой кабинет': 'Мой кабинет',
  Войти: 'Войти',
  Регистрация: 'Регистрация',
  'История заказов': 'История заказов',
  'Личная информация': 'Личная информация',
  'Мои отзывы': 'Мои отзывы',
  'Список желаний': 'Список желаний',
  Выйти: 'Выйти',
  'График работы': 'График работы',
  'Будние дни с 8 до 21': 'Будние дни с 8 до 21',
  'Выходные с 8 до 20': 'Выходные с 8 до 20',
  Ещё: 'Ещё',
  'Смотреть еще': 'Смотреть еще',
  Поиск: 'Поиск',
  'Говорите...': 'Говорите...',
  'Все результаты': 'Все результаты',
  'Все акции': 'Все акции',
  Новинки: 'Новинки',
  'Смотреть все': 'Смотреть все',
  'Воспользуйтесь скидкой уже сейчас или соберите еще': 'Воспользуйтесь скидкой уже сейчас или соберите еще',
  'бонусов для получения': 'бонусов для получения',
  'Станьте участником': 'Станьте участником',
  'бонусной программы': 'бонусной программы',
  'Накапливайте бонусы за каждую покупку и выбирайте лучшее для своих детей!':
    'Накапливайте бонусы за каждую покупку и выбирайте лучшее для своих детей!',
  'Условия покупки': 'Условия покупки',
  'Ваши вопросы (FAQ)': 'Ваши вопросы (FAQ)',
  Подписаться: 'Подписаться',
  'Подпишитесь и получайте новости об акциях и специальных предложениях':
    'Подпишитесь и получайте новости об акциях и специальных предложениях',
  'Наши контакты': 'Наши контакты',
  поддержки: 'поддержки',
  'Все права защищены': 'Все права защищены',
  'Call-центра': 'Call-центра',
  'Назад к покупкам': 'Назад к покупкам',
  'ООО "БэбиШоп"': 'ООО "БэбиШоп"',
  'Ваша корзина пуста': 'Ваша корзина пуста',
  'Ознакомьтесь с нашими': 'Ознакомьтесь с нашими',
  акциями: 'акциями',
  'К сожалению, товар': 'К сожалению, товар',
  'из Вашей корзины закончился': 'из Вашей корзины закончился',
  'Смотреть похожие': 'Смотреть похожие',
  'К сожалению, некоторые товары из вашей корзины закончились':
    'К сожалению, некоторые товары из вашей корзины закончились',
  'Перейти в корзину': 'Перейти в корзину',
  'в запрашиваемом количестве недоступен': 'в запрашиваемом количестве недоступен',
  'К сожалению, некоторые товары из вашей корзины недоступны':
    'К сожалению, некоторые товары из вашей корзины недоступны',
  'Участвуйте в бонусной программе': 'Участвуйте в бонусной программе',
  Присоединиться: 'Присоединиться',
  '*Бонусная карта действует на всей территории Украины в фирменных магазинах Chicco (адреса магазинов на сайте chicco.com.ua), в интернет-магазине chicco.com.ua и в фирменных магазинах MA (адреса магазинов на сайте ma.com.ua).':
    '*Бонусная карта действует на всей территории Украины в фирменных магазинах Chicco (адреса магазинов на сайте chicco.com.ua), в интернет-магазине chicco.com.ua и в фирменных магазинах MA (адреса магазинов на сайте ma.com.ua).',
  'Стать участником бонусной программы': 'Стать участником бонусной программы',
  'Магазины Chicco в городе': 'Магазины Chicco в городе',
  Фирменные: 'Фирменные',
  Партнерские: 'Партнерские',
  магазины: 'магазины',
  'Call-центр работает': 'Call-центр работает',
  'График работы Call-центра': 'График работы Call-центра',
  Поддержка: 'Поддержка',
  'Доставим на следующий день': 'Доставим на следующий день',
  'по будням': 'по будням',
  'в выходные': 'в выходные',
  с: 'c',
  'Узнавайте об акциях раньше всех': 'Узнавайте об акциях раньше всех',
  'Акционные товары в ограниченном количестве': 'Акционные товары в ограниченном количестве',
  'Подписаться на рассылку': 'Подписаться на рассылку',
  'Показать все акции': 'Показать все акции',
  'Официальный импортер в Украине:': 'Официальный импортер в Украине:',
  'ООО "Миллениум Трейд", 03680, г. Киев, ул. Физкультуры, 28':
    'ООО "Миллениум Трейд", 03680, г. Киев, ул. Физкультуры, 28',
  'Бесплатная доставка по Киеву за 24 часа': 'Бесплатная доставка по Киеву за 24 часа',
  'Гарантия до 36 месяцев': 'Гарантия до 36 месяцев',
  'Бонусы за каждую покупку': 'Бонусы за каждую покупку',

  'Пользователь уже зарегистрирован.': 'Пользователь уже зарегистрирован.',
  'Превышен лимит попыток регистрации.': 'Превышен лимит попыток регистрации.',
  'Пользователь не зарегистрирован.': 'Пользователь не зарегистрирован.',
  'Неверный телефон или пароль.': 'Неверный телефон или пароль.',
  'Превышен лимит запросов в час.': 'Превышен лимит запросов в час.',
  'Бесплатная доставка': 'Бесплатная доставка',
  'Добавлен подарок': 'Добавлен подарок',
  'В вашу корзину добавлен подарок за участие в акции': 'В вашу корзину добавлен подарок за участие в акции',
  'Выбрать подарок': 'Выбрать подарок',
  'Выберу позже': 'Выберу позже',
  Категории: 'Категории',
  'Установите ваш пароль': 'Установите ваш пароль',
  'Указать пароль позже': 'Указать пароль позже',
  'отправлен код': 'отправлен код',
  'Отправить код повторно': 'Отправить код повторно',
  'Неверный одноразовый код.': 'Неверный одноразовый код.',
  'Неверный формат передаваемых данных.': 'Неверный формат передаваемых данных.',
  'Телефон уже зарегистрирован.': 'Телефон уже зарегистрирован.',
  'Необходимо подтвердить номер телефона. Перейдите во вкладку "Регистрация" для завершения процедуры.':
    'Необходимо подтвердить номер телефона. Перейдите во вкладку "Регистрация" для завершения процедуры.',
  'Пароль не установлен. Воспользуйтесь кнопкой восстановления пароля для авторизации.':
    'Пароль не установлен. Воспользуйтесь кнопкой восстановления пароля для авторизации.',
  'Превышен лимит запросов.': 'Превышен лимит запросов.',
  'Превышен лимит запросов в минуту.': 'Превышен лимит запросов в минуту.',
  'Пожалуйста, введите свой телефон': 'Пожалуйста, введите свой телефон',
  'Мы отправим на этот номер код подтверждения': 'Мы отправим на этот номер код подтверждения',
  Продолжить: 'Продолжить',
  'Добавить в вишлист': 'Добавить в вишлист',
  'Переместить в вишлист': 'Переместить в вишлист',
  'пн-вс: с': 'пн-вс: с',
  до: 'до',
  // end MAIN
  // CATALOG
  'Для девочек': 'Для девочек',
  'Для мальчиков': 'Для мальчиков',
  Возраст: 'Возраст',
  Сортировать: 'Сортировать',
  Фильтр: 'Фильтр',
  Фильтры: 'Фильтры',
  Назад: 'Назад',
  Показать: 'Показать',
  Предзаказ: 'Предзаказ',
  Топ: 'Топ',
  Акция: 'Акция',
  'Оставить отзыв': 'Оставить отзыв',
  Ожидается: 'Ожидается',
  'Показать еще': 'Показать еще',
  'Ничего не найдено': 'Ничего не найдено',
  'Очистить фильтры': 'Очистить фильтры',
  'по рейтингу': 'по рейтингу',
  новинки: 'новинки',
  Новинка: 'Новинка',
  'максимальная скидка': 'максимальная скидка',
  'по возрастанию цены': 'по возрастанию цены',
  'по убыванию цены': 'по убыванию цены',
  'Товары по категориям': 'Товары по категориям',
  'Таблица размеров': 'Таблица размеров',
  'Показаны результаты по запросу': 'Показаны результаты по запросу',
  'Не найдено результатов по запросу': 'Не найдено результатов по запросу',
  'Результаты поиска по запросу': 'Результаты поиска по запросу',
  выгода: 'выгода',
  // end CATALOG
  // CABINET
  Аккаунт: 'Аккаунт',
  'Личный кабинет': 'Личный кабинет',
  Выход: 'Выход',
  Дата: 'Дата',
  заказа: 'заказа',
  Подробнее: 'Подробнее',
  Собирается: 'Собирается',
  'Передан на доставку': 'Передан на доставку',
  'В пути': 'В пути',
  Доставлен: 'Доставлен',
  'Частично собран': 'Частично собран',
  Собран: 'Собран',
  Способ: 'Способ',
  Получатель: 'Получатель',
  'Оплата наличными': 'Оплата наличными',
  'Оплата картой в магазине': 'Оплата картой в магазине',
  'Оплата картой курьеру': 'Оплата картой курьеру',
  'Ожидание оплаты': 'Ожидание оплаты',
  'Обработка платежа': 'Обработка платежа',
  Оплачено: 'Оплачено',
  Отмена: 'Отмена',
  Просрочено: 'Просрочено',
  Заказ: 'Заказ',
  от: 'от',
  'Если вы хотите вернуть товар': 'Если вы хотите вернуть товар',
  'Отменить заказ': 'Отменить заказ',
  Отменить: 'Отменить',
  Редактировать: 'Редактировать',
  Добавить: 'Добавить',
  'Изменение пароля': 'Изменение пароля',
  'изменить пароль': 'изменить пароль',
  'Текущий пароль': 'Текущий пароль',
  'Новый пароль': 'Новый пароль',
  'Подтвердите пароль': 'Подтвердите пароль',
  'Изменить Пароль': 'Изменить Пароль',
  'Фамилия и имя': 'Фамилия и имя',
  Пол: 'Пол',
  мужской: 'мужской',
  женский: 'женский',
  'В счастливом ожидании малыша': 'В счастливом ожидании малыша',
  'Дата рождения': 'Дата рождения',
  'Связь с социальными сетями для входа в один клик': 'Связь с социальными сетями для входа в один клик',
  Отвязать: 'Отвязать',
  Связать: 'Связать',
  Сохранить: 'Сохранить',
  Сохранено: 'Сохранено',
  Привязать: 'Привязать',
  'Пароль успешно изменен': 'Пароль успешно изменен',
  'Личная информация обновлена': 'Личная информация обновлена',
  'E-mail уже установлен или подтвержден': 'E-mail уже установлен или подтвержден',
  'Ошибка удаления': 'Ошибка удаления',
  'Ошибка сохранения': 'Ошибка сохранения',
  'Ошибка сохранения email': 'Ошибка сохранения email',
  'Успешно сохранено': 'Успешно сохранено',
  'Социальная сеть отключена': 'Социальная сеть отключена',
  'Социальная сеть подключена': 'Социальная сеть подключена',
  'Ошибка добавления соцсети': 'Ошибка добавления соцсети',
  'Пользователь с данным идентификатором уже существует': 'Пользователь с данным идентификатором уже существуетт',
  'Информация о детях': 'Информация о детях',
  'Для добавления детей обратитесь в call-центр': 'Для добавления детей обратитесь в call-центр',
  'Информация о ребенке сохранена': 'Информация о ребенке сохранена',
  'Информация о ребенке обновлена': 'Информация о ребенке обновлена',
  'Информация о ребенке удалена': 'Информация о ребенке удалена',
  мальчик: 'мальчик',
  девочка: 'девочка',
  Мальчик: 'Мальчик',
  Девочка: 'Девочка',
  Удалить: 'Удалить',
  'Удаление информации о ребенке': 'Удаление информации о ребенке',
  'Вы уверены, что хотите удалить информацию о ребенке?': 'Вы уверены, что хотите удалить информацию о ребенке?',
  Да: 'Да',
  'На ваш адрес': 'На ваш адрес',
  'отправлено подтверждение': 'отправлено подтверждение',
  'Письмо с подтверждением адреса действительно в течении 24 часов':
    'Письмо с подтверждением адреса действительно в течении 24 часов',
  'Мои адреса': 'Мои адреса',
  'Информация о адресе добавлена': 'Информация о адресе добавлена',
  'Информация о адресе обновлена': 'Информация о адресе обновлена',
  'Информация о адресе удалена': 'Информация о адресе удалена',
  'Успешно удалено': 'Успешно удалено',
  Отделение: 'Отделение',
  'Адресная доставка': 'Адресная доставка',
  'Номер отделения': 'Номер отделения',
  'Удаление адреса': 'Удаление адреса',
  'Вы уверены, что хотите удалить адрес?': 'Вы уверены, что хотите удалить адрес?',
  'Вы еще не оставляли отзывы': 'Вы еще не оставляли отзывы',
  Достоинства: 'Достоинства',
  'Ваша оценка': 'Ваша оценка',
  'Одно из трех полей обязательно для заполнения': 'Одно из трех полей обязательно для заполнения',
  Недостатки: 'Недостатки',
  'Сохранить отзыв': 'Сохранить отзыв',
  Изменить: 'Изменить',
  'Показать все': 'Показать все',
  комментарии: 'комментарии',
  все: 'все',
  'Удаление отзыва': 'Удаление отзыва',
  'Вы уверены, что хотите удалить отзыв?': 'Вы уверены, что хотите удалить отзыв?',
  'Ваш отзыв успешно обновлен, отправлен на обработку и будет отображен в ближайшее время':
    'Ваш отзыв успешно обновлен, отправлен на обработку и будет отображен в ближайшее время',
  'Списки желаний': 'Списки желаний',
  'Авторизуйтесь что бы воспользоваться всеми возможностями списка желаний':
    'Авторизуйтесь что бы воспользоваться всеми возможностями списка желаний',
  'Ваш список желаний пока пуст': 'Ваш список желаний пока пуст',
  'Добавляйте любимые товары в список, чтобы не забыть о них':
    'Добавляйте любимые товары в список, чтобы не забыть о них',
  'Добавить список': 'Добавить список',
  'Название списка желаний': 'Название списка желаний',
  'Название списка': 'Название списка',
  'Введите название списка': 'Введите название списка',
  'Добавив в него товары, вы можете вернуться к списку покупок в любое удобное время, а также поделиться им со своими друзьями и обсудить предстоящую покупку вместе':
    'Добавив в него товары, вы можете вернуться к списку покупок в любое удобное время, а также поделиться им со своими друзьями и обсудить предстоящую покупку вместе',
  'Редактировать название': 'Редактировать название',
  'Введите название': 'Введите название',
  'Ред.название': 'Ред.название',
  'По умолчанию': 'По умолчанию',
  Купить: 'Купить',
  Переместить: 'Переместить',
  'Cсылка скопирована': 'Cсылка скопирована',
  'Упс. Бонусная программа на профилактике. Попробуйте обновить страницу через несколько минут':
    'Упс. Бонусная программа на профилактике. Попробуйте обновить страницу через несколько минут',
  'Для участия в бонусной программе необходимо': 'Для участия в бонусной программе необходимо',
  'заполнить профиль': 'заполнить профиль',
  'Узнайте больше о преимуществах статусов': 'Узнайте больше о преимуществах статусов',
  'Обменивайте бонусы на скидку': 'Обменивайте бонусы на скидку',
  'Используйте бонусы для получения скидки при оплате любых товаров! Чем больше бонусов – тем выгодней обмен':
    'Используйте бонусы для получения скидки при оплате любых товаров! Чем больше бонусов – тем выгодней обмен',
  'Доступно бонусов': 'Доступно бонусов',
  'Воспользуйтесь скидкой сейчас': 'Воспользуйтесь скидкой сейчас',
  'или соберите еще': 'или соберите еще',
  'Соберите еще': 'Соберите еще',
  'Бонусы начисляются на 16-й день после покупки': 'Бонусы начисляются на 16-й день после покупки',
  'К начислению': 'К начислению',
  Подробно: 'Подробно',
  Бонусы: 'Бонусы',
  'Дата начисления': 'Дата начисления',
  'Совершите покупку, чтобы получить новые бонусы': 'Совершите покупку, чтобы получить новые бонусы',
  'Ваш статус': 'Ваш статус',
  Получайте: 'Получайте',
  'двойные бонусы': 'двойные бонусы',
  'в честь': 'в честь',
  'дня рождения': 'дня рождения',
  'вашего малыша': 'вашего малыша',
  'за каждую потраченную гривну': 'за каждую потраченную гривну',
  'Сумма покупок за текущий период': 'Сумма покупок за текущий период',
  Для: 'Для',
  сохранения: 'сохранения',
  'получения следующего': 'получения следующего',
  статуса: 'статуса',
  'необходимо купить на сумму': 'необходимо купить на сумму',
  'до конца срока действия текущего статуса': 'до конца срока действия текущего статуса',
  'Вы достигли максимального статуса, так держать': 'Вы достигли максимального статуса, так держать',
  'Статус действует по': 'Статус действует по',
  'Вы еще не оставляли свои отзывы у нас на сайте': 'Вы еще не оставляли свои отзывы у нас на сайте',
  'Посоветуйте другим то, что понравилось вам': 'Посоветуйте другим то, что понравилось вам',
  'На модерации': 'На модерации',
  Оставлен: 'Оставлен',
  'Отзыв оставлен': 'Отзыв оставлен',
  Оценка: 'Оценка',
  'за отзыв': 'за отзыв',
  Начислено: 'Начислено',
  Преимущества: 'Преимущества',
  Дети: 'Дети',
  'добавить ребенка': 'добавить ребенка',
  'Добавление ребенка': 'Добавление ребенка',
  'Ожидаю малыша': 'Ожидаю малыша',
  'Персональные скидки и подарки в день рождения малыша': 'Персональные скидки и подарки в день рождения малыша',
  "Поле 'Комментарий' обязательно для заполнения": "Поле 'Комментарий' обязательно для заполнения",
  'Ваш баланс': 'Ваш баланс',
  'или скидка': 'или скидка',
  'Вы можете воспользоваться бонусами при покупке любых товаров':
    'Вы можете воспользоваться бонусами при покупке любых товаров',
  'Мои контакты': 'Мои контакты',
  'Редактирование данных': 'Редактирование данных',
  'Мы сможем радовать вас выгодными акциями и спецпредложениями!':
    'Мы сможем радовать вас выгодными акциями и спецпредложениями!',
  Готово: 'Готово',
  'Ваш адрес электронной почты успешно подтвержден': 'Ваш адрес электронной почты успешно подтвержден',
  'E-mail уже существует': 'E-mail уже существует',
  'Сделать основным': 'Сделать основным',
  'Добавить новый адрес': 'Добавить новый адрес',
  'Мой основной адрес': 'Мой основной адрес',
  'Предполагаемая дата рождения': 'Предполагаемая дата рождения',
  'Предварительная дата родов': 'Предварительная дата родов',
  "'Предварительная дата рождения' обязательно для заполнения":
    "'Предварительная дата рождения' обязательно для заполнения",
  'Вы уверены, что хотите удалить информацию о предварительной дате рождения ребенка?':
    'Вы уверены, что хотите удалить информацию о предварительной дате рождения ребенка?',
  'Для участия в бонусной программе закончите': 'Для участия в бонусной программе закончите',
  'заполнение профиля': 'заполнение профиля',
  'бонусов для получения скидки в': 'бонусів для отримання знижки в',
  'Бонусы будут начислены на 16-й день после покупки': 'Бонусы будут начислены на 16-й день после покупки',
  'При оплате продуктов Собственного импорта. Даже во время распродажи.':
    'При оплате продуктов Собственного импорта. Даже во время распродажи.',
  'Чем больше бонусов — тем выгодней обмен!': 'Чем больше бонусов — тем выгодней обмен!',
  'История бонусного счета': 'История бонусного счета',
  'Подписка на рассылку по e-mail': 'Подписка на рассылку по e-mail',
  'Начисление за событие': 'Начисление за событие',
  Приглашение: 'Приглашение',
  Событие: 'Событие',
  Детали: 'Детали',
  Улица: 'Улица',
  'Список желаний пуст. Добавьте товары, которые вам понравились':
    'Список желаний пуст. Добавьте товары, которые вам понравились',
  'в список желаний, и сможете просматривать их в любое время':
    'в список желаний, и сможете просматривать их в любое время',
  'Действия со списком': 'Действия со списком',
  Переименовать: 'Переименовать',
  'Удалить список': 'Удалить список',
  Опубликован: 'Опубликован',
  Отклонен: 'Отклонен',
  'Есть вопрос': 'Есть вопрос',
  'Напишите нам': 'Напишите нам',
  'сб-вс': 'сб-вс',
  'пн-вс': 'пн-вс',
  'Также любые вопросы можно задать оператору call-center по телефону':
    'Также любые вопросы можно задать оператору call-center по телефону',
  'Обратите, пожалуйста, внимание на часто задаваемые вопросы в разделе':
    'Обратите, пожалуйста, внимание на часто задаваемые вопросы в разделе',
  'Возможно, ответ уже есть.': 'Возможно, ответ уже есть.',
  'Информация о предварительной дате рождения ребенка сохранена':
    'Информация о предварительной дате рождения ребенка сохранена',
  'Информация о предварительной дате рождения ребенка обновлена':
    'Информация о предварительной дате рождения ребенка обновлена',
  'Информация о предварительной дате рождения ребенка удалена':
    'Информация о предварительной дате рождения ребенка удалена',
  Списание: 'Списание',
  Начисление: 'Начисление',
  'Первый отзыв о товаре': 'Первый отзыв о товаре',
  'Переход на следующий уровень': 'Переход на следующий уровень',
  'Начисление за первую покупку друга': 'Начисление за первую покупку друга',
  'Ежегодный подарок': 'Ежегодный подарок',
  'Вы точно хотите безвозвратно удалить свой список желаний':
    'Вы точно хотите безвозвратно удалить свой список желаний',
  'вместе с добавленными в него товарами': 'вместе с добавленными в него товарами',
  // end CABINET
  // DETAIL-PAGE
  'Cпособы доставки': 'Cпособы доставки',
  'Гарантия и возврат': 'Гарантия и возврат',
  'При покупке будет начислено': 'При покупке будет начислено',
  'Узнай больше о бонусной программе': 'Узнай больше о бонусной программе',
  'Заказ в 1 клик': 'Заказ в 1 клик',
  'кешбэк начисляется за информативный и одобренный модераторами отзыв на товар, приобретенный на сайте на сайте chicco.com.ua или в магазинах Chicco ':
    'кешбэк начисляется за информативный и одобренный модераторами отзыв на товар, приобретенный на сайте на сайте chicco.com.ua или в магазинах Chicco ',
  'бонусов за отзыв': 'бонусов за отзыв',
  '+15 грн кешбэка за отзыв и +25 грн кешбэка отзыв с фото!*':
    '+15 грн кешбэка за отзыв и +25 грн кешбэка отзыв с фото!*',
  'Доставка от 2-х дней': 'Доставка от 2-х дней',
  'Ваш отзыв отправлен на обработку и появится в ближайшее время':
    'Ваш отзыв отправлен на обработку и появится в ближайшее время',
  'В наличии': 'В наличии',
  Код: 'Код',
  товара: 'товара',
  Цвет: 'Цвет',
  'Ваша выгода': 'Ваша выгода',
  Выгода: 'Выгода',
  'Добавить в корзину': 'Добавить в корзину',
  'снято с производства': 'снято с производства',
  'С этим товаром рекомендуем': 'С этим товаром рекомендуем',
  Фото: 'Фото',
  Видео: 'Видео',
  Описание: 'Описание',
  Характеристики: 'Характеристики',
  Отзывы: 'Отзывы',
  'Оплата и доставка': 'Оплата и доставка',
  'Наличие в магазинах': 'Наличие в магазинах',
  'Отправить по email': 'Отправить по email',
  Поделиться: 'Поделиться',
  'Скопировать ссылку': 'Скопировать ссылку',
  'В список желаний': 'В список желаний',
  'В списке желаний': 'В списке желаний',
  'на ваш счет': 'на ваш счет',
  'Узнайте больше о бонусной программе': 'Узнайте больше о бонусной программе',
  'Все видео': 'Все видео',
  'Все отзывы': 'Все отзывы',
  'Отзывов о данном товаре пока не оставляли': 'Отзывов о данном товаре пока не оставляли',
  'Получите 20 грн кешбэка за первый отзыв или 25 грн кешбэка за отзыв с фото!':
    'Получите 20 грн кешбэка за первый отзыв или 25 грн кешбэка за отзыв с фото!',
  'Все о товаре': 'Все о товаре',
  'Вы недавно смотрели': 'Вы недавно смотрели',
  'Вы добавили в корзину': 'Вы добавили в корзину',
  'на сумму': 'на сумму',
  Наименование: 'Наименование',
  Цена: 'Цена',
  цена: 'цена',
  'Кол-во': 'Кол-во',
  Сумма: 'Сумма',
  'Продолжить покупки': 'Продолжить покупки',
  Город: 'Город',
  Адрес: 'Адрес',
  'К сожалению, в данный момент в городе': 'К сожалению, в данный момент в городе',
  'товара нет в наличии': 'товара нет в наличии',
  'Выберите другой город': 'Выберите другой город',
  'Выберите другой цвет или размер': 'Выберите другой цвет или размер',
  'В данный момент товар отсутствует в фирменных магазинах': 'В данный момент товар отсутствует в фирменных магазинах',
  'Ссылка скопирована!': 'Ссылка скопирована!',
  'Ссылка скопирована': 'Ссылка скопирована',
  'Список магазинов': 'Список магазинов',
  'Показать на карте': 'Показать на карте',
  Опубликовать: 'Опубликовать',
  'На карте': 'На карте',
  Скрыть: 'Скрыть',
  'Чтобы управлять картой, коснитесь её 2 раза': 'Чтобы управлять картой, коснитесь её 2 раза',
  'Скрыть карту': 'Скрыть карту',
  'Проложить маршрут': 'Проложить маршрут',
  'Выберите магазин': 'Выберите магазин',
  'Есть в магазинах': 'Есть в магазинах',
  // end DETAIL-PAGE
  // ORDER
  'Подтверждая заказ, я принимаю условия': 'Подтверждая заказ, я принимаю условия',
  'пользовательского соглашения': 'пользовательского соглашения',
  'Не перезванивать для подтверждения заказа': 'Не перезванивать для подтверждения заказа',
  'Получатель не я': 'Получатель не я',
  'Количество товаров': 'Количество товаров',
  'Стоимость товаров': 'Стоимость товаров',
  'Доставка курьером по Киеву': 'Доставка курьером по Киеву',
  'бесплатно, 1-2 дней': 'бесплатно, 1-2 дня',
  'Доставка курьером по Украине (Meest)': 'Доставка курьером по Украине (Meest)',
  'бесплатно, 2-5 дней': 'бесплатно, 2-5 дней',
  'бесплатно, 30 минут - до 3-х дней': 'бесплатно, 30 минут - до 3-х дней',
  'Доставка в отделение “Нова Пошта”': 'Доставка в отделение “Нова Пошта”',
  '50 грн, 1-3 дней': '50 грн, 1-3 дней',
  'бесплатно, 1-3 дней': 'бесплатно, 1-3 дня',
  'Экспресс-доставка iPOST': 'Экспресс-доставка iPOST',
  '180 грн, от 3-х часов': '180 грн, от 3-х часов',
  'Выберите сохранённый адрес из списка': 'Выберите сохранённый адрес из списка',
  'Сохранённый адрес': 'Сохранённый адрес',
  'Новый адрес': 'Новый адрес',
  'Картой курьеру': 'Картой курьеру',
  'картой курьеру': 'картой курьеру',
  'Есть сертификат?': 'Есть сертификат?',
  'Есть промокод?': 'Есть промокод?',
  'Есть комментарии?': 'Есть комментарии?',
  'Есть комментарии к заказу?': 'Есть комментарии к заказу?',
  'Недействительный промокод': 'Недействительный промокод',
  'Недействительный сертификат': 'Недействительный сертификат',
  'Применен сертификат': 'Применен сертификат',
  'Применен промокод': 'Применен промокод',
  'Комментарий к заказу': 'Комментарий к заказу',
  Применить: 'Применить',
  'Введите код': 'Введите код',
  'Для оплаты будет отправлен инвойс через LiqPay или в приложение Приват 24':
    'Для оплаты будет отправлен инвойс через LiqPay или в приложение Приват 24',
  'Доставим по адресу': 'Доставим по адресу',
  'Изменить заказ': 'Изменить заказ',
  Размер: 'Размер',
  Артикул: 'Артикул',
  Закрыть: 'Закрыть',
  'Спасибо за покупку': 'Спасибо за покупку',
  'Номер заказа': 'Номер заказа',
  'Адрес доставки': 'Адрес доставки',
  'Самовывоз из': 'Самовывоз из',
  'Сумма заказа': 'Сумма заказа',
  Доставка: 'Доставка',
  Бесплатно: 'Бесплатно',
  бесплатно: 'бесплатно',
  'Оплачено бонусами': 'Оплачено бонусами',
  'Оплачено сертификатами': 'Оплачено сертификатами',
  'Успешно оплачено картой': 'Успешно оплачено картой',
  Использовано: 'Использовано',
  Оплата: 'Оплата',
  Картой: 'Картой',
  картой: 'картой',
  Наличными: 'Наличными',
  наличными: 'наличными',
  'Картой в магазине': 'Картой в магазине',
  'картой в магазине': 'картой в магазине',
  'К оплате': 'К оплате',
  'Оплачено картой': 'Оплачено картой',
  'Оплачено картой "Пакунок малюка"': 'Оплачено картой "Пакунок малюка"',
  'Оплачено "Частями от ПриватБанк"': 'Оплачено "Частями от ПриватБанк"',
  Успешно: 'Успешно',
  'Начислено бонусов': 'Начислено бонусов',
  'На ваш счет будет начислено': 'На ваш счет будет начислено',
  'Стань участником бонусной программы и получи welcome-бонус':
    'Стань участником бонусной программы и получи welcome-бонус',
  'Стать участником': 'Стать участником',
  'На главную': 'На главную',
  'Отслеживать заказ': 'Отслеживать заказ',
  'Будьте в курсе акций': 'Будьте в курсе акций',
  'Отследить заказ': 'Отследить заказ',
  Товаров: 'Товаров',
  'Товаров в вашем заказе': 'Товаров в вашем заказе',
  'На сумму': 'На сумму',
  Скидка: 'Скидка',
  'Использовано бонусов': 'Использовано бонусов',
  Итого: 'Итого',
  'Бонусов за заказ': 'Бонусов за заказ',
  'Пользовательское соглашение': 'Пользовательское соглашение',
  'Подтверждая заказ, я принимаю': 'Подтверждая заказ, я принимаю',
  'условия пользовательского': 'условия пользовательского',
  соглашения: 'соглашения',
  'Постоянный клиент': 'Постоянный клиент',
  'Новый покупатель': 'Новый покупатель',
  'Контактная информация': 'Контактная информация',
  'Способ доставки': 'Способ доставки',
  'Доставка по адресу': 'Доставка по адресу',
  'Ваш заказ': 'Ваш заказ',
  'Редактировать заказ': 'Редактировать заказ',
  'Добавить комментарий': 'Добавить комментарий',
  Комментарий: 'Комментарий',
  'Не перезванивать мне': 'Не перезванивать мне',
  'Доступно к списанию': 'Доступно к списанию',
  'Оплатить бонусами': 'Оплатить бонусами',
  'Ваша скидка': 'Ваша скидка',
  Выгодная: 'Выгодная',
  Максимальная: 'Максимальная',
  'Выбрать скидку': 'Выбрать скидку',
  'Заказ верный, звонок менеджера не требуется': 'Заказ верный, звонок менеджера не требуется',
  'Сохраненные адреса': 'Сохраненные адреса',
  'Добавить адрес': 'Добавить адрес',
  'Способ оплаты': 'Способ оплаты',
  'Оплата картой': 'Оплата картой',
  'Для оплаты картой "Пакунок малюка" на e-mail будет отправлен инвойс через LiqPay':
    'Для оплаты картой "Пакунок малюка" на e-mail будет отправлен инвойс через LiqPay',
  'Ваш город': 'Ваш город',
  Телефон: 'Телефон',
  Имя: 'Имя',
  Фамилия: 'Фамилия',
  'и фамилия': 'и фамилия',
  'Если вы хотите следить за статусом выполнения заказа': 'Если вы хотите следить за статусом выполнения заказа',
  'Для применения и просмотра бонусов, пожалуйста': 'Для применения и просмотра бонусов, пожалуйста',
  авторизуйтесь: 'авторизуйтесь',
  'Скидка бонусов': 'Скидка бонусов',
  'Ввести промокод': 'Ввести промокод',
  Промокод: 'Промокод',
  Cертификат: 'Cертификат',
  'Заказ подтверждаю': 'Заказ подтверждаю',
  Количество: 'Количество',
  Статуc: 'Статуc',
  Новый: 'Новый',
  'Готов к выдаче': 'Готов к выдаче',
  Отменен: 'Отменен',
  'Ожидается доставка': 'Ожидается доставка',
  'Отказ покупателя': 'Отказ покупателя',
  'Нова Пошта': 'Нова Пошта',
  Justin: 'Justin',
  'Мои заказы': 'Мои заказы',
  '№ заказа': '№ заказа',
  Статус: 'Статус',
  'У Вас пока нет заказов': 'У Вас пока нет заказов',
  'Предлагаем ознакомиться с нашими': 'Предлагаем ознакомиться с нашими',
  'Вы еще не делали заказ у нас на сайте': 'Вы еще не делали заказ у нас на сайте',
  'Попробуйте, и вам обязательно понравится': 'Попробуйте, и вам обязательно понравится',
  'В ожидании обработки': 'В ожидании обработки',
  Свернуть: 'Свернуть',
  'Читать полностью': 'Читать полностью',
  'Детали заказа': 'Детали заказа',
  Доставлено: 'Доставлено',
  Подарок: 'Подарок',
  'Оформление заказа': 'Оформление заказа',
  'Констультации по телефону': 'Констультации по телефону',
  'Произвести оплату кешбэком при самовывозе возможно в точке выдачи заказа':
    'Произвести оплату кешбэком при самовывозе возможно в точке выдачи заказа',
  'Скидка бонусами': 'Скидка бонусами',
  'Отделение перевозчика': 'Отделение перевозчика',
  'Ошибка оформления заказа. К сожалению, все товары из вашей корзины закончились.':
    'Ошибка оформления заказа. К сожалению, все товары из вашей корзины закончились.',
  'Ошибка оформления заказа. Попробуйте оформить заказ повторно. Если ошибка повторяется, свяжитесь с нами по телефону (044) 323-09-06':
    'Ошибка оформления заказа. Попробуйте оформить заказ повторно. Если ошибка повторяется, свяжитесь с нами по телефону (044) 323-09-06',
  'Удалить товар из корзины?': 'Удалить товар из корзины?',
  // end ORDER
  // BLOG-NEWS-PROMO-BRANDS
  Воспитание: 'Воспитание',
  Развитие: 'Развитие',
  Досуг: 'Досуг',
  'Правильное питание': 'Правильное питание',
  Акции: 'Акции',
  Осталось: 'Осталось',
  Еще: 'Еще',
  'Акция завершена': 'Акция завершена',
  'До конца акции': 'До конца акции',
  'Акции, конкурсы и события': 'Акции, конкурсы и события',
  'Назад к списку': 'Назад к списку',
  'Выберите город': 'Выберите город',
  // end BLOG-NEWS-PROMO-BRANDS
  // PERSONAL-ACCOUNT
  'Основной адрес': 'Основной адрес',
  'основной адрес': 'основной адрес',
  День: 'День',
  Месяц: 'Месяц',
  Год: 'Год',
  Дом: 'Дом',
  дом: 'дом',
  Квартира: 'Квартира',
  'Копировать код': 'Копировать код',
  'Приведи подругу': 'Приведи подругу',
  'Делитесь промокодом': 'Делитесь промокодом',
  'Добавление адреса': 'Добавление адреса',
  'Редактирование адреса': 'Редактирование адреса',
  'Редактирование основного адреса': 'Редактирование основного адреса',
  'Добавьте свой первый адрес': 'Добавьте свой первый адрес',
  'Ваш промокод скоро будет тут! Попробуйте обновить страницу через несколько минут. Если промокод не появится - обратитель в call-центр по тел. (044) 323-09-12':
    'Ваш промокод скоро будет тут! Попробуйте обновить страницу через несколько минут. Если промокод не появится - обратитель в call-центр по тел. (044) 323-09-12',
  'Список желаний пуст. Возможно, товары будут добавлены позже':
    'Список желаний пуст. Возможно, товары будут добавлены позже',
  // end PERSONAL-ACCOUNT
  // MODALS
  Вход: 'Вход',
  Спасибо: 'Спасибо',
  'за подписку': 'за подписку',
  'Вы уже': 'Вы уже',
  подписаны: 'подписаны',
  'подписывались ранее!': 'подписывались ранее!',
  Дарим: 'Дарим',
  'на первую покупку': 'на первую покупку',
  Получить: 'Получить',
  'Добавить в cписок желаний': 'Добавить в cписок желаний',
  'Выберите размер': 'Выберите размер',
  'Войти с помощью номера телефона': 'Войти с помощью номера телефона',
  Пароль: 'Пароль',
  'Или продолжить, используя социальные сети': 'Или продолжить, используя социальные сети',
  'Отправить пароль повторно': 'Отправить пароль повторно',
  'Восстановление пароля': 'Восстановление пароля',
  'Подтвердите свой номер телефона, чтобы войти в личный кабинет':
    'Подтвердите свой номер телефона, чтобы войти в личный кабинет',
  Далее: 'Далее',
  Здравствуйте: 'Здравствуйте',
  'Ваш Статус': 'Ваш Статус',
  Получите: 'Получите',
  'для получения': 'для получения',
  'Станьте участником бонусной программы': 'Станьте участником бонусной программы',
  Соберите: 'Соберите',
  'и получите': 'и получите',
  'Просто заполните профиль в личном кабинете': 'Просто заполните профиль в личном кабинете',
  'На сайт': 'На сайт',
  'Вы хотите перейти в личный кабинет или вернуться на сайт':
    'Вы хотите перейти в личный кабинет или вернуться на сайт',
  'Ждите подтверждения на почте': 'Ждите подтверждения на почте',
  'Спасибо за подписку. Ожидайте письмо с подтверждением на почте':
    '<b>Спасибо за подписку.</b><br> Ожидайте письмо с подтверждением на почте',
  'Вы успешно подтвердили email': 'Вы успешно подтвердили email',
  'Токен на найден, либо его время жизни истекло': 'Токен на найден, либо его время жизни истекло',
  'Ошибка подтверждения email': 'Ошибка подтверждения email',
  Заказать: 'Заказать',
  // end MODALS
  Сотрудничество: 'Сотрудничество',
  'Контакты менеджеров в сфере оптовых закупок можно найти в разделе':
    'Контакты менеджеров в сфере оптовых закупок можно найти в разделе',
  Партнеры: 'Партнеры',
  'Страница не найдена': 'Страница не найдена',
  'построить маршрут': 'построить маршрут',
  'Время работы': 'Время работы',
  Подтвердить: 'Подтвердить',
  'Легко присоединиться': 'Легко присоединиться',
  'Просто заполните анкету, чтобы стать участником бонусной программы':
    'Просто заполните анкету, чтобы стать участником бонусной программы',
  'Весело покупать': 'Весело покупать',
  'За каждую покупку с': 'За каждую покупку с',
  'вы получаете бонусы, которые активируются через 15 дней.':
    'вы получаете бонусы, которые активируются через 15 дней.',
  '1 потраченная гривна': '1 потраченная гривна',
  '1 накопленный бонус': '1 накопленный бонус',
  'это 1 бонус на вашем счете. Бонусы начисляются даже во время акций или распродаж.':
    'это 1 бонус на вашем счете. Бонусы начисляются даже во время акций или распродаж.',
  'Приятно бейбишопиться': 'Приятно бейбишопиться',
  'Выгодно использовать': 'Выгодно использовать',
  'Оплачивайте бонусами до 50% от стоимости продуктов Собственного импорта и получайте скидку':
    'Оплачивайте бонусами до 50% от стоимости продуктов Собственного импорта и получайте скидку',
  'даже во время акций и распродаж.': 'даже во время акций и распродаж.',
  'Быстро накапливать': 'Быстро накапливать',
  'Легко использовать': 'Легко использовать',
  'Чем больше вы покупаете, тем больше Бонусов получаете.': 'Чем больше вы покупаете, тем больше Бонусов получаете.',
  'Сумма покупок*': 'Сумма покупок*',
  'Коэффициент начисления бонусов': 'Коэффициент начисления бонусов',
  'Бонусы при получении статуса': 'Бонусы при получении статуса',
  'X2 бонусы в День рождения вашего ребенка': 'X2 бонусы в День рождения вашего ребенка',
  'Бонусы не сгорают': 'Бонусы не сгорают',
  'Подарок на День рождения вашего ребенка (до 3-х лет)': 'Подарок на День рождения вашего ребенка (до 3-х лет)',
  'Доступ к избранным акциям за день до начала': 'Доступ к избранным акциям за день до начала',
  'Обмен товаров до 30 дней (вместо 14 дней)': 'Обмен товаров до 30 дней (вместо 14 дней)',
  '2000 бонусов в подарок 1 раз в год': '2000 бонусов в подарок 1 раз в год',
  '*Бонусная карта действует на всей территории Украины в фирменных магазинах Chicco (адреса магазинов на сайте chicco.com.ua), в интернет-магазине chicco.com.ua, в фирменных магазинах Stokke (адреса магазинов на сайте Stokke.com.ua) и в интернет-магазине Stokke.com.ua и в фирменных магазинах Babyshop (адреса магазинов на сайте babyshop.ua).':
    '*Бонусная карта действует на всей территории Украины в фирменных магазинах Chicco (адреса магазинов на сайте chicco.com.ua), в интернет-магазине chicco.com.ua, в фирменных магазинах Stokke (адреса магазинов на сайте Stokke.com.ua) и в интернет-магазине Stokke.com.ua и в фирменных магазинах Babyshop (адреса магазинов на сайте babyshop.ua).',
  'Более детально с правилами Бонусной программы Babycard вы можете ознакомиться здесь:':
    'Более детально с правилами Бонусной программы Babycard вы можете ознакомиться здесь:',
  'Правила Бонусной программы Babycard': 'Правила Бонусной программы Babycard',
  'Личные данные': 'Личные данные',
  "Поле 'Е-mail' должно быть действительным электронным адресом":
    "Поле 'Е-mail' должно быть действительным электронным адресом",
  'Продолжить оформление': 'Продолжить оформление',
  'Ой, похоже этой страницы не существует...': 'Ой, похоже этой страницы не существует...',
  'Но у нас есть много интересного для вас': 'Но у нас есть много интересного для вас',
  // SOCIAL-PROJETS, MOMS-SCHOOL
  '8 января 2017 года': '8 января 2017 года',
  'Сердца Киборгов: Новогодние каникулы': 'Сердца Киборгов: Новогодние каникулы',
  '28 декабря 2016 года': '28 декабря 2016 года',
  'Мы в Коростышеве': 'Мы в Коростышеве',
  '1 сентября 2016 года': '1 сентября 2016 года',
  'Сердца Киборгов: День знаний': 'Сердца Киборгов: День знаний',
  '2 декабря 2013 года': '2 декабря 2013 года',
  'Мы снова в Новограде': 'Мы снова в Новограде',
  '5 июня 2011 года': '5 июня 2011 года',
  'Мы в Охматдет, Львов': 'Мы в Охматдет, Львов',
  '5 июня 2010 года': '5 июня 2010 года',
  'Мы в Жмеринке': 'Мы в Жмеринке',
  'Занятия в Школе мам Chicco подарят вам множество важной информации о беременности, родах, грудном вскармливании и жизни с малышом.':
    'Занятия в Школе мам Chicco подарят вам множество важной информации о беременности, родах, грудном вскармливании и жизни с малышом.',
  'Расписание лекций': 'Расписание лекций',
  'Записи лекций': 'Записи лекций',
  'Расписание ожидается в ближайшее время': 'Расписание ожидается в ближайшее время',
  смотрите: 'смотрите',
  'Лекции Школы мам': 'Лекции Школы мам',
  'на youtube': 'на youtube',
  смотреть: 'смотреть',
  'Оплата частями': 'Оплата частями',
  'Оплата частями от ПриватБанк': 'Оплата частями от ПриватБанк',
  'Количество платежей': 'Количество платежей',
  'Сумма одного платежа': 'Сумма одного платежа',
  платежа: 'платежа',
  месяц: 'месяц',
  Выбрать: 'Выбрать',
  'Выберите количество платежей при оплате частями': 'Выберите количество платежей при оплате частями',
  'Необходимо выбрать количество платежей.': 'Необходимо выбрать количество платежей.',
  'Выберите количество платежей': 'Выберите количество платежей',
  'Оплачено частями от ПриватБанк': 'Оплачено частями от ПриватБанк',
  Сертификаты: 'Сертификаты',
  'бесплатно от 400 грн, 1-2 дней': 'бесплатно от 400 грн, 1-2 дней',
  'бесплатно от 400 грн, 2-5 дней': 'бесплатно от 400 грн, 2-5 дней',
  'Номер телефону має починатись з +380': 'Номер телефона должен начинаться с +380',
  'Оберіть відділення на карті': 'Выбрать отделение на карте',
  'Почніть вводити вулицю': 'Начните вводить улицу',
  monday: 'Пн',
  tuesday: 'Вт',
  wednesday: 'Ср',
  thursday: 'Чт',
  friday: 'Пт',
  saturday: 'Сб',
  sunday: 'Вс',
  Вихідний: 'Выходной',
  'На мапі': 'На карте',
  Список: 'Список',
  'Доступна оплата бонусами': 'Доступна оплата бонусами',
  'Оплата бонусами недоступна': 'Оплата бонусами недоступна',
  'на ваш рахунок': 'на ваш счет',
  'для владельцев': 'для владельцев',
  Подписка: 'Подписка',
  'МА Клуб': 'МА Клуб',
  'МА Клуб: до': 'МА Клуб: до',
  'Еще больше брендов': 'Еще больше брендов',
  'Детальнее здесь': 'Детальнее здесь',
  'Ваша МА Подписка': 'Участие в МА Клубе', // changed only value to keep u-component version for master
  'действует до': 'до',
  Продовжити: 'Продолжить',
  Продлить: 'Продлить',
  'Читать инструкцию': 'Читать инструкцию',
  Инструкция: 'Инструкция',
  noscriptDefaultTitle: 'Скорее всего в вашем браузере отключён JavaScript.',
  noscriptDefaultText:
    'Вы должны включить JavaScript в вашем браузере, чтобы использовать все возможности этого сайта.',
  'Добавить в cписок желаний?': 'Добавить в cписок желаний?',
  'Добавить в список': 'Добавить в список',
  'Создать список': 'Создать список',
  'На вашем счету недостаточно кешбэка. Пожалуйста, обновите страницу и попробуйте оформить заказ повторно.':
    'На вашем счету недостаточно кешбэка. Пожалуйста, обновите страницу и попробуйте оформить заказ повторно.',
  'К сожалению, программа МА Кешбэк временно недоступна. Попробуйте оформить заказ через несколько минут.':
    'К сожалению, программа МА Кешбэк временно недоступна. Попробуйте оформить заказ через несколько минут.',
  'Вы выбрали': 'Вы выбрали',
  'Переместить в список': 'Переместить в список',
  'Ліміт: до кг': 'Лимит: до {weight} кг',
  'Самовывоз из магазина': 'Самовывоз из магазина',
  'Максимум 5 файлів': 'Максимум 5 файлов',
  'Добавить фото': 'Добавить фото',
  Ответить: 'Ответить',
  'Мінімальна сума для оплати частинами': 'Минимальная сумма для оплаты частями 300 грн',
  'Максимальна сума для оплати частинами': 'Максимальная сумма для оплаты по частям 100000 грн',
  'Дата повинна бути сьогодні або пізніше': 'Дата должна быть сегодня или позже',
  р: 'г',
  Бонуси: 'Бонусы',
  'Загрузить фото': 'Загрузить фото',
  'Поле обовязкове до заповнення': 'Поле {field} обязательно для заполнения',
  'Мінімальна довжина поля': 'Поле {field} должно содержать от {length} символов',
  'Максимальна довжина поля': 'Поле {field} не может быть больше {length} символов',
  'Довжина поля': 'Поле {field} должно содержать {length} символов',
  'Тільки літери': 'Поле {field} может содержать только буквы',
  'Тільки числа': 'Поле {field} должно быть числовым',
  'Не співпадає': "Поле {field} не совпадает с полем 'Подтвердите пароль'",
  'Мінімальне значення': 'Поле {field} должно быть {min} или более',
  'Максимальне значення': 'Поле {field} должно быть {max} или меньше',
  'Тільки літери і тире': 'Поле {field} может содержать только буквы и тире',
  'Введите корректное название города': 'Введите корректное название города',
  'Помилка авторизації. Спробуйте пізніше або інший метод авторизації.':
    'Ошибка авторизации. Попытайтесь позже или другой метод авторизации.',
  'Спробуйте пізніше або інший метод авторизації.': 'Попытайтесь позже или другой метод авторизации.',
  'Ошибка авторизации': 'Ошибка авторизации',
  'Мы нашли пользователя с таким телефоном среди наших клиентов. Это Вы? Тогда войдите на сайт с помощью номера телефона и пароля и привяжите социальную сеть в личном кабинете.':
    'Мы нашли пользователя с таким телефоном среди наших клиентов. Это Вы? Тогда войдите на сайт с помощью номера телефона и пароля и привяжите социальную сеть в личном кабинете.',
  'Уже купил': 'Уже купил',
  'Показать все комментарии': 'Показать все комментарии',
  'Скрыть все комментарии': 'Скрыть все комментарии',
  Номиналы: 'Номиналы',
  'Данные получателя': 'Данные получателя',
  'Дата отправки сертификата на e-mail': 'Дата отправки сертификата на e-mail',
  'Текст поздравления': 'Текст поздравления',
  'Дата отправки': 'Дата отправки',
  'E-mail получателя': 'E-mail получателя',
  'Курьер "Нова Пошта"': 'Курьер "Нова Пошта"',
  'Отделение "Нова Пошта"': 'Отделение "Нова Пошта"',
  'Почтомат "Нова Пошта"': 'Почтомат "Нова Пошта"',
  'Отделение "Укрпошта"': 'Отделение "Укрпошта"',
  'Отделение "Justin"': 'Отделение "Justin"',
  'Курьер "Meest"': 'Курьер "Meest"',
  'Курьер по Киеву': 'Курьер по Киеву',
  Самовывоз: 'Самовывоз',
  'Курьер iPOST': 'Курьер iPOST',
  'Доставка в отделение': 'Доставка в отделение',
  'Максимальный номинал сертификата 100 000 грн': 'Максимальный номинал сертификата 100 000 грн',
  'Минимальный номинал сертификата 50 грн': 'Минимальный номинал сертификата 50 грн',
  'Этот адрес уже был сохранен': 'Этот адрес уже был сохранен',
  'двойной кешбэк': 'двойной кешбэк',
  'Кешбэк за каждую покупку': 'Кешбэк за каждую покупку',
  'Программа МА Кешбэк': 'Программа МА Кешбэк',
  'МА Кешбэк': 'МА Кешбэк',
  'Доступно кешбэка': 'Доступно кешбэка',
  'Доступна оплата кешбэком': 'Доступна оплата кешбэком',
  'Оплата кешбэком недоступна': 'Оплата кешбэком недоступна',
  'Узнай больше о программе МА Кешбэк ': 'Узнай больше о программе МА Кешбэк ',
  кешбэка: 'кешбэка',
  Оплатить: 'Оплатить',
  'Оплачено кешбэком': 'Оплачено кешбэком',
  'Начислено кешбэка': 'Начислено кешбэка',
  'Используйте кешбэк при оплате продуктов Cобственного импорта':
    'Используйте кешбэк при оплате продуктов Собственного импорта',
  'Упс. Программа МА Кешбэк на профилактике. Попробуйте обновить страницу через несколько минут':
    'Упс. Программа МА Кешбэк на профилактике. Попробуйте обновить страницу через несколько минут',
  'Для участия в программе МА Кешбэк закончите': 'Для участия в программе МА Кешбэк закончите',
  'Кешбэк начисляется на 16-й день после покупки': 'Кешбэк начисляется на 16-й день после покупки',
  Кешбэк: 'Кешбэк',
  'Совершите покупку, чтобы получить кешбэк': 'Совершите покупку, чтобы получить кешбэк',
  'История начислений': 'История начислений',
  'Станьте участником программы': 'Станьте участником программы',
  'кешбэка за каждую покупку': 'кешбэка за каждую покупку',
  'Оплачивайте МА Кешбэком до 50% от стоимости продуктов Собственного импорта':
    'Оплачивайте МА Кешбэком до 50% от стоимости продуктов Собственного импорта',
  'Присоединяйтесь и получайте выгодный': 'Присоединяйтесь и получайте выгодный',
  'Накапливайте МА Кешбэк за каждую покупку и выбирайте лучшее для своих детей!':
    'Накапливайте МА Кешбэк за каждую покупку и выбирайте лучшее для своих детей!',
  '% начисления МА Кешбэка': '% начисления МА Кешбэка',
  'Срок действия статуса': 'Срок действия статуса',
  '1 год с момента приобретения': '1 год с момента приобретения',
  '2 года с момента приобретения': '2 года с момента приобретения',
  '1 год с момента последней покупки': '1 год с момента последней покупки',
  'Срок действия МА Кешбэка': 'Срок действия МА Кешбэка',
  'X2 МА Кешбэк ко Дню рождения вашего ребенка (от 3-х лет)':
    'X2 МА Кешбэк ко Дню рождения вашего ребенка (от 3-х лет)',
  'Подарок при обретении статуса': 'Подарок при обретении статуса',
  'Доступ к выбранным акциям за день до начала/закрытые распродажи':
    'Доступ к выбранным акциям за день до начала/закрытые распродажи',
  '* Сумма покупок за год с момента перехода на новый статус Guest, Friend или Family или за 2 года с момента перехода на статус Dynasty':
    '* Сумма покупок за год с момента перехода на новый статус Guest, Friend или Family или за 2 года с момента перехода на статус Dynasty',
  'Бренды-участники программы': 'Бренды-участники программы',
  'Партнеры программы': 'Партнеры программы',
  'Вознаграждение за отзывы на сайте': 'Вознаграждение за отзывы на сайте',
  'Вознаграждение за отзывы в соцсетях': 'Вознаграждение за отзывы в соцсетях',
  'Вознаграждение за регистрацию': 'Вознаграждение за регистрацию',
  'регистрация в программе МА Кешбэк': 'регистрация в программе МА Кешбэк',
  'подписка на рассылку писем': 'подписка на рассылку писем',
  'все фото- или видеоотзывы': 'все фото- или видеоотзывы',
  'первый текстовый отзыв': 'первый текстовый отзыв',
  'следующие текстовые отзывы': 'следующие текстовые отзывы',
  'отзыв в сториз (с отметкой аккаунта @ma.com.ua.official)':
    'отзыв в сториз<br> (с отметкой аккаунта @ma.com.ua.official)',
  'видео- или фотосообщение': 'видео- или фотосообщение',
  'партнеры программы МА Кешбэк': 'партнеры программы МА Кешбэк',
  'Всего 45 секунд, чтобы заполнить анкету и активировать ваш аккаунт.':
    'Всего <b>45 секунд</b>, чтобы заполнить анкету и активировать ваш аккаунт.',
  'Покупая какие-либо продукты, вы получаете МА Кешбэк, активируемую на 16-й день. МА Кешбэк начисляется даже во время акций и распродаж. В зависимости от статуса в программе МА Кешбэк вы получаете 3, 5, 7 или 10% от покупки в виде МА Кешбэка на ваш счет в программе МА Кешбэк.':
    'Покупая какие-либо продукты, вы получаете МА Кешбэк, активируемую на 16-й день. МА Кешбэк <b>начисляется даже во время акций и распродаж.</b> В зависимости от статуса в программе МА Кешбэк вы получаете 3, 5, 7 или 10% от покупки в виде кешбэка на ваш счет в программе МА Кешбэк.',
  'Выгодно покупать': 'Выгодно покупать',
  'Оплачивайте МА Кешбэком до 50% от стоимости продуктов Собственного импорта. МА Кешбэк можно использовать даже во время акций и распродаж.':
    'Оплачивайте МА Кешбэком до 50% от стоимости продуктов Собственного импорта. МА Кешбэк можно использовать <b>даже во время акций и распродаж</b>.',
  'Чем больше сумма покупок, тем больше процент начисления МА Кешбэка.':
    'Чем больше сумма покупок, тем больше процент начисления МА Кешбэка.',
  'Более детально с правилами программы МА Кешбэк вы можете ознакомиться здесь:':
    'Более детально с правилами программы МА Кешбэк вы можете ознакомиться здесь:',
  'Правила Кешбэк программы': 'Правила Кешбэк программы',
  'Скидка до 50%': 'Скидка до 50%',
  'Узнайте больше о преимуществах статусов в программе': 'Узнайте больше о преимуществах статусов в программе',
  'Стать участником программы': 'Стать участником программы',
  'и получите 30 грн кешбэка.': 'и получите 50 грн кешбэка.',
  'Стать участником программы МА Кешбэк': 'Стать участником программы МА Кешбэк',
  'На Вашем счету': 'На Вашем счету',
  'Оплата кешбэком': 'Оплата кешбэком',
  'Кешбэк к начислению': 'Кешбэк к начислению',
  'Выберите дату': 'Выберите дату',
  'Выберите пол': 'Выберите пол',
  "Поле 'Город' необходимо иметь не менее 2-х буквенных символов":
    "Поле 'Город' необходимо иметь не менее 2-х буквенных символов",
  'Невозможно сохранить': 'Невозможно сохранить',
  'Чтобы стать участником программы МА Кешбэк нужно заполнить поля: Имя, Фамилия, E-mail':
    'Чтобы стать участником программы МА Кешбэк нужно заполнить поля: Имя, Фамилия, E-mail',
  'Текущий пароль указано неверно': 'Текущий пароль указано неверно',
  'Номер дома': 'Номер дома',
  'Ошибка отправки письма': 'Ошибка отправки письма',
  'Обратитесь в техническую поддержку': 'Обратитесь в техническую поддержку',
  'Сегодня вы уже получили письмо с подтверждением': 'Сегодня вы уже получили письмо с подтверждением',
  'Вы исчерпали количество попыток': 'Вы исчерпали количество попыток',
  'Срок действия кешбэка до': 'Срок действия кешбэка до',
  'Войти в аккаунт': 'Войти в аккаунт',
  'Мы вас узнали!': 'Мы вас узнали!',
  'У вас уже есть аккаунт': 'У вас уже есть аккаунт',
  'К-ство частей': 'К-ство частей',
  'Введите сумму оплаты': 'Введите сумму оплаты',
  'Купить подарочные сертификаты': 'Купить подарочные сертификаты',
  'Нет магазинов': 'Нет магазинов',
  'Отзывы о покупке товаров в категории': 'Отзывы о покупке товаров в категории',
  '*Электронный подарочный сертификат – сертификат указанного вами номинала. Необходимо лишь выбрать дизайн подарочного сертификата, указать электронную почту получателя, написать поздравление и оплатить заказ. Мамины Ассистенты позаботьсятся, чтобы получатель вовремя получил подарок через e-mail-сообщение.':
    '*Электронный подарочный сертификат – сертификат указанного вами номинала. Необходимо лишь выбрать дизайн подарочного сертификата, указать электронную почту получателя, написать поздравление и оплатить заказ. Мамины Ассистенты позаботьсятся, чтобы получатель вовремя получил подарок через e-mail-сообщение.',
  '**Классический подарочный сертификат – физический подарочный сертификат в подарочном конверте, который можно приобрести в фирменном магазине или на сайте с его последующим получением через курьера или удобную вам транспортую компанию.':
    '**Классический подарочный сертификат – физический подарочный сертификат в подарочном конверте, который можно приобрести в фирменном магазине или на сайте с его последующим получением через курьера или удобную вам транспортую компанию.',
  'Более детально с правилами использования Подарочных сертификатов можно ознакомиться здесь:':
    'Более детально с правилами использования Подарочных сертификатов можно ознакомиться здесь:',
  'Правила использования Подарочных сертификатов': 'Правила использования Подарочных сертификатов',
  '***Дополнительную информацию о правилах использования Подарочных сертификатов можно получить по телефону горячей линии':
    '***Дополнительную информацию о правилах использования Подарочных сертификатов можно получить по телефону горячей линии',
  'Отображать отделения, доступные для доставки заказа': 'Отображать отделения, доступные для доставки заказа',
  'Изображение не удалось загрузить': 'Изображение {name} не удалось загрузить',
  'До начала акции': 'До начала акции',
  'Выберите свой тариф МА Подписки': 'Выберите свой тариф МА Клуба' // changed only value to keep u-component version for master
};
