import { contentStore } from 'u-store';

const { state: contentState = {}, mutations: contentMutations = {}, actions: contentActions = {} } = contentStore;

export const state = contentState;

export const mutations = {
  ...contentMutations
};

export const actions = {
  ...contentActions
};
