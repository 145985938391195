<template>
  <div class="container">
    <div class="promo-subscription-wrapper">
      <div class="promo-subscription-body">
        <div class="promo-subscription-title">
          <div class="promo-subscription-title__text">{{ $t('Узнавайте об акциях раньше всех') }}</div>
          <div class="promo-subscription-title__description">
            {{ $t('Акционные товары в ограниченном количестве') }}
          </div>
        </div>
        <UcForm form-class="promo-subscription-form" :handler="submit">
          <uc-input
            v-model="userEmail"
            rules="required|email"
            type="email"
            name="E-mail"
            input-classes="promo-subscription-form__input"
            placeholder="Ваш e-mail"
          />
          <uc-loader-button
            class="button uc-button-blue-and-white"
            :loading="loading"
            :disabled="loading"
            type="submit"
            >{{ $t('Подписаться на рассылку') }}</uc-loader-button
          >
        </UcForm>
      </div>
    </div>
  </div>
</template>

<script>
const USER_ALREADY_EXIST_EMAIL = 1211;

export default {
  data() {
    return {
      userEmail: null,
      loading: false
    };
  },
  methods: {
    submit() {
      this.loading = true;
      this.$api.UserService.eSputnikSubscribe('footer', this.userEmail)
        .then(() => {
          this.$eventBus.$emit('subscribe:modal:show');
          this.$gtm.setESEmailAction(this.userEmail);
          this.userEmail = null;
        })
        .catch((error) => {
          if (error.response?.data?.code === USER_ALREADY_EXIST_EMAIL) {
            this.$eventBus.$emit('user-exists:modal:show');
          } else {
            console.error('Subscribe Error:', error);
          }
        })
        .finally(() => (this.loading = false));
    }
  }
};
</script>

<style lang="scss">
@import '~/assets/scss/components/subscription.scss';
</style>
