<template>
  <section>
    <div v-if="blogSlides.slides.length" class="blog">
      <div class="blog-head">
        <div class="main-title">
          {{ $t('Блог') }}
        </div>
        <uc-link :to="localePath({ name: 'blog-page' })" class="blog-link">
          {{ $t('Смотреть все') }}
        </uc-link>
      </div>

      <div v-if="blogSlides.slides[0].slug" class="blog-main">
        <uc-link
          class="blog-main__img"
          :to="localePath({ name: 'blog-detail-page', params: { slug: blogSlides.slides[0].slug } })"
        >
          <uc-image :img="blogSlides.slides[0].mainImage" folder="content-image" alt="blog-img" title="image" />
        </uc-link>
        <div class="blog-main__text">{{ blogSlides.slides[0].h1 }}</div>
      </div>

      <div v-if="blogSlides.slides.length > 1" class="blog-blocks">
        <div v-for="(slider, index) in blogSlides.slides.slice(1, 3)" :key="index" class="blog-blocks__item">
          <uc-link
            v-if="slider && slider.slug"
            :key="`blog-link-${index}`"
            :to="localePath({ name: 'blog-detail-page', params: { slug: slider.slug } })"
            class="blog-block__img"
          >
            <uc-image
              v-if="slider && slider.sliderImage"
              :img="slider.sliderImage"
              folder="content-image"
              alt="blog-img"
              title="image"
            />
          </uc-link>
          <div v-if="slider && slider.h1" :key="`blog-text-${index}`" class="blog-block__text">
            <div class="blog-block__heading">{{ slider.h1 }}</div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    blogSlides: {
      type: Object,
      default: () => ({})
    }
  }
};
</script>
