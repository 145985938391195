<template>
  <button class="uc-button" :type="type" :disabled="disabled" @click="callback($event)">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'UcButton',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'button'
    }
  },
  methods: {
    callback(e) {
      this.$emit('click', e);
    }
  }
};
</script>
