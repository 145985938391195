<template>
  <div class="uc-tabs">
    <div v-if="visible" class="uc-tabs_nav">
      <div
        v-for="(tab, index) in tabs"
        :key="index"
        class="uc-tab"
        :class="{ active: tab.type === selected, disabled: tab.disabled }"
        @click="setTab(tab)"
      >
        <div v-if="tab.icon" class="uc-tab_icon" :class="tab.icon" />
        <img v-if="!tab.imageSelected && tab.image" class="uc-tab_image" :src="tab.image" />
        <template v-if="tab.imageSelected && tab.image">
          <img v-if="tab.type === selected" class="uc-tab_image" :src="tab.imageSelected" />
          <img v-else class="uc-tab_image" :src="tab.image" />
        </template>
        <div class="uc-tab_title">
          {{ $t(`${tab.name}`) }}<span v-if="tab.count">&nbsp;({{ tab.count }})</span>
        </div>
      </div>
    </div>
    <div class="uc-tabs_content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'UcTabs',
  props: {
    visible: {
      type: Boolean,
      default: true
    },
    tabs: {
      type: Array,
      default: () => []
    },
    selected: {
      type: String,
      default: ''
    },
    fullObjectResponse: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    setTab(tab) {
      this.$emit('selected', this.fullObjectResponse ? tab : tab.type);
    }
  }
};
</script>
