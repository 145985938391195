<template>
  <div class="image-wrapper">
    <img v-if="isSlide" :src="getImgUrl(img)" :alt="alt" :title="title" :class="[className]" />

    <client-only v-else>
      <div v-lazy-container="lazyLoadConfig">
        <img :data-src="getImgUrl(img)" :alt="alt" :title="title" :class="[className]" />
      </div>
    </client-only>

    <noscript inline-template>
      <img :src="getImgUrl(img)" :alt="alt" :title="title" :class="[className]" />
    </noscript>
  </div>
</template>

<script>
// TODO: Test this noscript
export default {
  name: 'UcImage',
  props: {
    img: {
      type: String,
      default: ''
    },
    alt: {
      type: String,
      default: 'image'
    },
    title: {
      type: String,
      default: 'image'
    },
    width: {
      type: String,
      default: 'AUTO'
    },
    height: {
      type: String,
      default: 'AUTO'
    },
    folder: {
      type: String,
      default: 'images'
    },
    className: {
      type: String,
      default: ''
    },
    loader: {
      type: Boolean,
      default: true
    },
    displayDefaultImg: {
      type: Boolean,
      default: true
    },
    isSlide: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      imageLoading: true,
      isMounted: false
    };
  },
  mouted() {
    this.isMounted = true;
  },
  computed: {
    wrapperWidth() {
      let width = '';

      if (this.width !== 'AUTO' || this.height !== 'AUTO') {
        width = this.width !== 'AUTO' ? this.width / 2 : this.height / 2;
      }

      return width;
    },
    wrapperHeight() {
      let height = '';
      if (this.width !== 'AUTO' || this.height !== 'AUTO') {
        height = this.height !== 'AUTO' ? this.height / 2 : this.width / 2;
      }

      return height;
    },
    lazyLoadConfig() {
      const config = {
        selector: 'img',
        error: this.getImgUrl('no_image.jpg', 'images')
      };

      return config;
    }
  },
  methods: {
    getImgUrl(img, folder) {
      if (!img) {
        return '';
      }

      const imgfolder = folder || this.folder;
      let url = '';
      const gifСheck = img.lastIndexOf('.gif') + 1;

      if (process.env.RESIZE_IMG && !gifСheck) {
        const dimensions = this.width || this.height ? `${this.width}x${this.height}/` : '';
        url = `${process.env.BASE_IMG_URL}/${imgfolder}/${dimensions}${img}`;
      }

      if (!process.env.RESIZE_IMG || (process.env.RESIZE_IMG && gifСheck)) {
        url = `${process.env.BASE_IMG_URL}/${imgfolder}/${img}`;
      }

      return url;
    }
  }
};
</script>

<style lang="scss" scoped>
.hidden {
  visibility: hidden;
  position: absolute;
}

.image-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  img[lazy='loading'] {
    width: 40px;
    height: 40px;
    max-width: 40px;
    max-height: 40px;
    border: 3px solid transparent;
    border-left: 3px solid $main-color;
    border-top: 3px solid $main-color;
    border-radius: 50%;
    animation: spin 0.5s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
</style>
