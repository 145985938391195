<template>
  <div id="map-component">
    <div class="map-wrap">
      <div v-show="isMapCoverActive && isScreenMobile" class="mobile-touch-cover">
        <div class="cover" @touchstart="onCoverTouchStartHandler" />
        <div class="mobile-touch-cover__info" @touchstart="onCoverTouchStartHandler">
          {{ $t('Чтобы управлять картой, коснитесь её 2 раза') }}
        </div>
      </div>
      <client-only>
        <div id="mapContainer"></div>
      </client-only>
      <div v-if="activeMarker" ref="popup" class="store-info">
        <span class="shop-title">{{ activeMarker.title }}</span>
        <uc-render-html
          v-if="activeMarker.address"
          root-element="p"
          class="store-address"
          :html="activeMarker.address"
        />
        <uc-render-html
          v-if="activeMarker.schedule"
          root-element="p"
          class="store-time"
          :html="activeMarker.schedule"
        />
        <uc-render-html v-if="activeMarker.phone" root-element="p" class="store-phone" :html="activeMarker.phone" />
        <a
          v-if="activeMarker.coordinates && activeMarker.coordinates[0] && activeMarker.coordinates[1]"
          :href="`https://www.google.com/maps/dir/?api=1&destination=${activeMarker.coordinates}`"
          target="_blank"
          class="shop-route"
        >
          {{ $t('построить маршрут') }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import ScreenWidthMixin from './mixin/ScreenWidth';

export default {
  name: 'UcMap',
  mixins: [ScreenWidthMixin],
  props: {
    markers: {
      type: Array,
      required: true
    },
    center: {
      type: Array,
      default: () => [50.450418, 30.523541] // Kиїв
    },
    zoom: {
      type: Number,
      default: 10
    },
    isPopupOpen: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      mapContainer: null,
      activeMarker: null,
      isMapCoverActive: true,
      lastTapTime: null,
      timeout: null,
      markersLayers: null
    };
  },
  watch: {
    center() {
      if (this.mapContainer && this.center) {
        const zoom = this.mapContainer._zoom < 12 ? 12 : this.mapContainer._zoom;
        this.mapContainer.setView(this.center, zoom);
      }
    },
    markers: {
      handler() {
        if (this.markersLayers) {
          this.markersLayers.forEach((layer) => {
            this.mapContainer.removeLayer(layer);
          });
        }
        this.addMarkers();
      }
    }
  },
  async created() {
    if (process.client) {
      this.$L = await import(/* webpackChunkName: "leaflet" */ 'leaflet').then((res) => res.default);
      this.$eventBus.$on('map:marker_popup:display', this.showPopupWithMarker);
      this.createMap();
    }
  },
  beforeDestroy() {
    this.$eventBus.$off('map:marker_popup:display', this.showPopupWithMarker);
  },
  methods: {
    async loadIcons() {
      try {
        delete this.$L.Icon.Default.prototype._getIconUrl;
        this.$L.Icon.Default.mergeOptions({
          iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
          iconUrl: require('leaflet/dist/images/marker-icon.png'),
          shadowUrl: require('leaflet/dist/images/marker-shadow.png')
        });
      } catch (error) {
        this.$sentry?.captureException(error);
      }
    },
    showPopupWithMarker(slug) {
      const target = this.markersLayers.find((layer) => layer.options.slug === slug);
      if (target) {
        this.mapContainer.panTo(target._latlng);
        target.openPopup();
        this.handleMarkerClick({ target });
      }
    },
    createMap() {
      this.mapContainer = this.$L.map('mapContainer').setView(this.center, this.zoom);
      this.mapContainer.on('zoom', this.zoomHandler);

      this.$L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(this.mapContainer);
      this.loadIcons();
      this.addMarkers();

      // Check if need to open on start
      if (this.isPopupOpen && this.markers.length) {
        this.showPopupWithMarker(this.markers[0].slug);
      }
    },
    addMarkers() {
      this.markersLayers = this.markers.map((marker) => {
        const markerEl = this.prepareMarker(marker);
        markerEl.addTo(this.mapContainer);
        return markerEl;
      });
    },
    prepareMarker(marker) {
      const markerEl = this.$L
        .marker(this.$L.latLng(marker.coordinates), {
          slug: marker.slug
        })
        .bindPopup(marker.name)
        .on('popupopen', this.handleMarkerClick);

      return markerEl;
    },
    async handleMarkerClick({ target }) {
      this.markersLayers.forEach((layer) => layer._icon?.classList.remove('active-icon'));
      this.activeMarker = this.markers.find((marker) => marker.slug === target.options.slug);
      /*
        Get popup with filled data for map popup
      */
      await this.$nextTick();
      const content = this.$refs.popup;
      target.setPopupContent(content);
      target._icon.classList.add('active-icon');
      this.$emit('markerClick', this.activeMarker);
    },
    // Mobile cover methods
    onCoverTouchStartHandler() {
      if (this.isDoubleTap()) {
        this.isMapCoverActive = false;
        this.runPostponeMapCover();
      }

      this.lastTapTime = new Date().getTime();
    },
    runPostponeMapCover() {
      this.timeout = setTimeout(() => {
        this.isMapCoverActive = true;
      }, 2000);
    },
    clearTimeout() {
      clearTimeout(this.timeout);
    },
    isDoubleTap() {
      const nowTime = new Date().getTime();
      const interval = nowTime - this.lastTapTime;

      return interval < 600;
    },
    zoomHandler() {
      this.clearTimeout();
      this.runPostponeMapCover();
    }
  }
};
</script>

<style src="leaflet/dist/leaflet.css" />

<style lang="scss" scoped>
.map-wrap {
  position: relative;
  z-index: 12;
  height: 500px;
  .mobile-touch-cover {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2000;
    user-select: none;
    .cover {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
      background-color: rgba(29, 30, 24, 0.3);
      user-select: none;
    }
    .mobile-touch-cover__info {
      width: 440px;
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      margin: 0 auto;
      text-align: center;
      user-select: none;
      z-index: 11;
      @media (max-width: 575px) {
        width: 300px;
      }
    }
  }
  #mapContainer {
    height: 100%;
    font-family: inherit !important;
  }
  .leaflet-control-attribution {
    display: none;
  }
}
</style>
