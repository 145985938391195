<template>
  <section>
    <div v-if="newsSlides.slides.length" class="banners">
      <div class="banners__head">
        <div class="main-title">{{ $t('Новости') }}</div>
        <uc-link :to="localePath({ name: 'news-page' })" class="banners-link">
          {{ $t('Смотреть все') }}
        </uc-link>
      </div>
      <client-only>
        <swiper :options="swiperOption">
          <swiper-slide v-for="(news, index) in newsSlides.slides" :key="`news-${index}`" class="banners__item">
            <uc-link
              v-if="news.slug"
              :title="news.h1"
              :aria-label="news.h1"
              :to="localePath({ name: 'news-detail-page', params: { slug: news.slug } })"
            >
              <uc-image
                class="banners-img-desktop"
                :img="news.mainImage"
                folder="content-image"
                :alt="news.slug"
                :title="news.h1"
              />
              <uc-image
                class="banners-img-mobile"
                :img="news.mobileImage"
                folder="content-image"
                :alt="news.slug"
                :title="news.h1"
              />
            </uc-link>
          </swiper-slide>
        </swiper>
        <template slot="placeholder">
          <div class="banners-nojs">
            <div v-for="(news, index) in newsSlides.slides.slice(0, 4)" :key="`news-${index}`" class="banners__item">
              <uc-link
                v-if="news.slug"
                :title="news.h1"
                :aria-label="news.h1"
                :to="localePath({ name: 'news-detail-page', params: { slug: news.slug } })"
              >
                <uc-image
                  class="banners-img-desktop"
                  :img="news.mainImage"
                  folder="content-image"
                  :alt="news.slug"
                  :title="news.h1"
                />
                <uc-image
                  class="banners-img-mobile"
                  :img="news.mobileImage"
                  folder="content-image"
                  :alt="news.slug"
                  :title="news.h1"
                />
              </uc-link>
            </div>
          </div>
        </template>
      </client-only>

      <div
        slot="pagination"
        class="pagination-news pagination-circle"
        :class="{ 'display-none': newsSlides.slides.length <= slidesCount }"
      ></div>
    </div>
  </section>
</template>

<script>
import { ScreenWidthMixin } from 'u-mixins';

export default {
  mixins: [ScreenWidthMixin],
  props: {
    newsSlides: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      swiperOption: {
        spaceBetween: 20,
        slidesPerView: 4,
        loop: false,
        autoplay: {
          delay: 3500,
          disableOnInteraction: false
        },
        pagination: {
          el: '.pagination-news',
          clickable: true
        },
        breakpoints: {
          767: {
            slidesPerView: 4
          },
          300: {
            slidesPerView: 2
          }
        }
      }
    };
  },
  computed: {
    slidesCount() {
      return this.isScreenMobile ? 2 : 4;
    }
  }
};
</script>

<style lang="scss">
@import '~/assets/scss/components/pagination-circle.scss';
</style>
