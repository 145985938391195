<template>
  <label
    :for="`uc-checkbox-${name}`"
    class="uc-checkbox-container"
    :class="{ 'uc-checkbox-container_disabled': disabled }"
  >
    <slot name="before-checkbox"></slot>
    <input :id="`uc-checkbox-${name}`" v-model="innerValue" :disabled="disabled" type="checkbox" />
    <span class="uc-checkbox" />
    <slot></slot>
  </label>
</template>

<script>
export default {
  name: 'UcCheckbox',
  props: {
    value: {
      type: Boolean,
      required: true
    },
    name: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      innerValue: this.value
    };
  },
  watch: {
    value() {
      if (this.value !== this.innerValue) {
        this.innerValue = this.value;
      }
    },
    innerValue() {
      this.$emit('input', this.innerValue);
    }
  }
};
</script>
