<template>
  <div v-if="visibility" id="ucModal">
    <div class="uc-modal-backdrop" @mousedown.self="hide()">
      <div
        class="uc-modal"
        role="dialog"
        :class="{
          'uc-modal--full': fullScreen,
          'uc-modal--active': animated,
          'uc-modal-overflow-visible': showOverflow
        }"
      >
        <slot />
        <img
          v-if="closeButton"
          class="uc-modal-close"
          src="https://static.chicco.com.ua/assets/svg/modal-close.svg"
          alt="close"
          @click="hide()"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UcModal',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    fullScreen: {
      type: Boolean,
      default: false
    },
    closeButton: {
      type: Boolean,
      default: true
    },
    showOverflow: {
      type: Boolean,
      default: false
    },
    toggleScroll: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      visibility: false,
      animated: false
    };
  },
  watch: {
    visibility(isOpen) {
      this.holdScroll(isOpen);
      this.$emit('input', isOpen);
    },
    value: {
      handler() {
        this.visibility = this.value;
        setTimeout(() => (this.animated = this.value));
      },
      immediate: true
    }
  },
  beforeDestroy() {
    this.holdScroll(false);
    this.hide();
  },
  methods: {
    holdScroll(bool) {
      if (this.toggleScroll) {
        this.$eventBus.$emit('general:body:hold-scroll', bool);
      }
    },
    hide() {
      this.visibility = false;
    }
  }
};
</script>
