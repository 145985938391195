<template>
  <section>
    <div v-if="filteredProducts && filteredProducts.length" class="selected-products">
      <div class="selected-products__head">
        <div v-if="title" class="slider-title">{{ title }}</div>
        <uc-link v-if="link" :to="link" class="product-slides__more">{{ $t('Смотреть еще') }}</uc-link>
      </div>
      <div class="selected-products-row">
        <div class="selected-products-arrow left" :class="slug"></div>
        <client-only>
          <swiper :options="productSwiperOption">
            <swiper-slide v-for="(product, index) in filteredProducts" :key="`product-${index}-${product.id}`">
              <product-item
                :product="product"
                :show-details="false"
                :rating="rating"
                :gtm-payload="gtmPayload"
                :position="index + 1"
              />
            </swiper-slide>
          </swiper>

          <template slot="placeholder">
            <div class="selected-products-nojs">
              <div
                v-for="(product, index) in filteredProducts.slice(0, 4)"
                :key="`product-${index}-${product.id}`"
                class="selected-products-nojs__item"
              >
                <product-item :product="product" :show-details="false" :rating="rating" :position="index + 1" />
              </div>
            </div>
          </template>
        </client-only>
        <div class="selected-products-arrow right" :class="slug"></div>
      </div>
      <div
        slot="pagination"
        class="pagination-circle"
        :class="[slug, { 'display-none': filteredProducts.length <= 1 }]"
      ></div>
    </div>
  </section>
</template>

<script>
import ProductItem from '~/src/components/general/productItem/ProductItem.vue';

export default {
  components: {
    ProductItem
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    link: {
      type: String,
      default: ''
    },
    products: {
      type: Array,
      default: () => []
    },
    slug: {
      type: String,
      default: 'selected-products'
    },
    gtmEnabled: {
      type: Boolean,
      default: false
    },
    // GTM prop
    itemListId: {
      type: String,
      default: ''
    },
    // GTM prop
    itemListName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      productSwiperOption: {
        spaceBetween: 24,
        pagination: {
          el: `.${this.slug}.pagination-circle`,
          clickable: true
        },
        navigation: {
          prevEl: `.${this.slug}.left`,
          nextEl: `.${this.slug}.right`
        },
        breakpoints: {
          1920: {
            slidesPerView: 5
          },
          767: {
            slidesPerView: 4
          },
          320: {
            slidesPerView: 2,
            spaceBetween: 16
          }
        }
      },
      rating: []
    };
  },
  computed: {
    filteredProducts() {
      return this.products.filter((item) => item.totalAmount);
    },
    gtmPayload() {
      return {
        item_list_id: this.itemListId || this.slug,
        item_list_name: this.itemListName || this.title
      };
    }
  },
  mounted() {
    if (this.products.length) {
      this.getProductsRating();
    }
    if (this.gtmEnabled) {
      this.sendToGtmViewed();
    }
  },
  methods: {
    getProductsRating() {
      const productsId = this.products.map((product) => product.id);
      this.$api.ContentService.getRatingForCatalogProducts(productsId)
        .then(({ data }) => {
          this.rating = data;
        })
        .catch((e) => {
          console.error('Get Rating For Product Slider Error:', e);
        });
    },
    sendToGtmViewed() {
      this.$gtm.searchResultEvent(this.products, this.$route, this.gtmPayload);
    }
  }
};
</script>

<style lang="scss">
@import '~/assets/scss/components/pagination-circle.scss';
</style>
