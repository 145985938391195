<template>
  <div class="store-full-data" :class="{ active }" @click="onClick(shop)">
    <div class="place">
      <span>{{ shop.count && shop.count }}</span>
    </div>
    <div class="store-info shops-item">
      <h2 class="shops-item__title">{{ shop.title && shop.title }}</h2>
      <p class="shops-item__city">{{ shop.city }}</p>
      <uc-render-html
        v-if="shop.address"
        root-element="p"
        class="store-address shops-item__address"
        :html="shop.address"
      />
      <p v-if="shop.phone" class="store-phone shops-item__telephone">
        <uc-render-html :html="callPhone" root-element="span" />
      </p>
      <p v-if="shop.schedule" class="store-time shops-item__time">
        <span>{{ $t('Время работы') }}:</span><br />
        <uc-render-html root-element="span" class="store-time shops-item__schedule" :html="shop.schedule" />
      </p>
    </div>
    <div v-if="false" class="store-galery" tooltip="Смотреть фото">
      <img src="https://static.chicco.com.ua/assets/svg/warehouse/photo.svg" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    shop: {
      type: Object,
      default: () => ({})
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    callPhone() {
      let phone = '';
      if (this.active && this.shop.phone) {
        const plainePhone = this.shop.phone.replace(/[^\d]/g, '');
        phone = `<a  href="tel:${plainePhone}">${this.shop.phone}</a>`;
      }

      if (!this.active && this.shop.phone) {
        phone = this.shop.phone;
      }

      return phone;
    }
  },
  methods: {
    onClick(shop) {
      this.$emit('click', shop);
    }
  }
};
</script>
