import Vue from 'vue';
import i18nConfig from '../../i18n.conf.json';
import { getRouteNameWithoutLocale, getUrlWithLocale, getLocalizedRouteName } from '../../utils/i18n-url';

const DEFAULT_LOCALE_CODE_FOR_X_DEFAULT = 'ua';

function i18nSeo(path) {
  const locale = i18nConfig.locales.find(({ code }) => code === this.i18n.locale) || null;

  const seo = { link: [], htmlAttrs: { lang: (locale && locale.htmlAttrsLang) || this.i18n.locale } };

  (i18nConfig.locales || []).forEach(({ code, hreflang }) => {
    seo.link.push({
      hid: `i18n-alt-${code}`,
      rel: 'alternate',
      href: `${process.env.PROJECT_BASE_URL}${toLocalePath(path || this.route.path, code)}`,
      hreflang
    });
  });

  seo.link.push({
    hid: 'x-default',
    rel: 'alternate',
    href: `${process.env.PROJECT_BASE_URL}${toLocalePath(path || this.route.path, DEFAULT_LOCALE_CODE_FOR_X_DEFAULT)}`,
    hreflang: 'x-default'
  });

  return seo;
}

function checkCurrentRouteName(baseRouteName) {
  return getLocalizedRouteName(baseRouteName, this.i18n.locale) === this.route.name;
}

function toLocalePath(path, locale) {
  locale = (locale !== undefined && locale) || this.i18n.locale;
  locale = (locale !== i18nConfig.hiddenLocale && locale) || '';

  return getUrlWithLocale(path, locale);
}

function localePath(route, locale) {
  locale = locale || this.i18n.locale;

  route.params = Object.entries(route.params || {})
    .map(([key, value]) => {
      if (value !== undefined && !Array.isArray(value) && value && value.match(/\//g)) {
        value = value.split('/');
      }

      return [key, value];
    })
    .reduce((result, [key, value]) => {
      result[key] = value;

      return result;
    }, {});

  const routeName = getLocalizedRouteName(getRouteNameWithoutLocale(route.name), locale);
  const toRoute = this.router.resolve({
    path: route.path,
    name: routeName,
    params: route.params,
    hash: route.hash,
    query: route.query
  });
  const fullPath = (toRoute && toRoute.route && toRoute.route.fullPath) || route.fullPath;
  return toLocalePath((fullPath && fullPath.replace(/%2F/g, '/')) || fullPath, locale);
}

function switchLocalePath(locale) {
  locale = locale || this.i18n.locale;
  const fullPath = this.route.fullPath;
  return toLocalePath((fullPath && fullPath.replace(/%2F/g, '/')) || fullPath, locale);
}

const NuxtContextProxy = function (context, targetFunction) {
  return function () {
    const { app, req, route, store } = context;

    const proxy = {
      getRouteBaseName: app.getRouteBaseName,
      i18n: app.i18n,
      localePath: app.localePath,
      req: process.server ? req : null,
      route,
      router: app.router,
      store
    };

    return targetFunction.apply(proxy, arguments);
  };
};

const VueInstanceProxy = function (targetFunction) {
  return function () {
    const proxy = {
      getRouteBaseName: this.getRouteBaseName,
      i18n: this.$i18n,
      localePath: this.localePath,
      req: process.server ? this.$ssrContext.req : null,
      route: this.$route,
      router: this.$router,
      store: this.$store
    };

    return targetFunction.apply(proxy, arguments);
  };
};

export default (context) => {
  const { app } = context;

  if (!Vue.__i18nLocalePath__) {
    Vue.__i18nLocalePath__ = true;
    Vue.mixin({
      methods: {
        toLocalePath: VueInstanceProxy(toLocalePath),
        localePath: VueInstanceProxy(localePath),
        switchLocalePath: VueInstanceProxy(switchLocalePath),
        checkCurrentRouteName: VueInstanceProxy(checkCurrentRouteName),
        i18nSeo: VueInstanceProxy(i18nSeo)
      }
    });
  }

  app.toLocalePath = NuxtContextProxy(context, toLocalePath);
  app.localePath = NuxtContextProxy(context, localePath);
  app.switchLocalePath = NuxtContextProxy(context, switchLocalePath);
  app.checkCurrentRouteName = NuxtContextProxy(context, checkCurrentRouteName);
  app.i18nSeo = NuxtContextProxy(context, i18nSeo);
};
