<template>
  <div class="wish-list">
    <div v-if="!isActive" class="card-like" @click="clickHandler()">
      <NotFilledHeart />
    </div>
    <uc-link v-if="isActive" :to="localePath({ name: 'wish-list-page' })" class="card-like">
      <RedHeart />
    </uc-link>
  </div>
</template>

<script>
import catalogWidgetMixin from 'u-wishlist/lib/mixins/widgets/catalogWidget.mixin.js';
import { RedHeart, NotFilledHeart } from '~/src/icons';

export default {
  components: { RedHeart, NotFilledHeart },
  mixins: [catalogWidgetMixin]
};
</script>
