<template>
  <div v-if="isSelected">
    <slot />
  </div>
</template>
<script>
export default {
  name: 'UcTab',
  props: {
    isSelected: {
      type: Boolean,
      default: false
    }
  }
};
</script>
