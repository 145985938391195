<template>
  <span class="uc-size" :class="{ 'uc-size-active': isActive, 'uc-size-disabled': disabled }" @click="clickHandler()">
    <input v-model="isChecked" :disabled="disabled" type="checkbox" />
    <slot></slot>
  </span>
</template>

<script>
export default {
  name: 'UcSize',
  props: {
    value: {
      type: Array,
      default: () => []
    },
    current: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isChecked: false
    };
  },
  computed: {
    isActive() {
      return this.value.find((item) => item === this.current);
    }
  },
  watch: {
    value: {
      handler() {
        this.isChecked = (this.value || []).includes(this.current);
      },
      immediate: true
    }
  },
  methods: {
    clickHandler() {
      if (this.disabled) {
        return null;
      }

      this.isChecked = !this.isChecked;
      let data = [...this.value];
      if (this.isChecked) {
        data.push(this.current);
      } else {
        data = data.filter((val) => val !== this.current);
      }

      this.$emit('click', this.current);
      this.$emit('input', data);
    }
  }
};
</script>
