import { orderStore } from 'u-store';
const { mutationTypes: orderMutation } = orderStore;
import { DefaultDeliveryMethods } from '~/store/order/DefaultDeliveryMethods.js';

export const state = () => ({
  refreshRequestTime: 0
});

export const mutations = {
  // refresh check
  SET_REFRESH_REQUEST_TIME(state, refreshRequestTime) {
    state.refreshRequestTime = refreshRequestTime;
  }
};

export const actions = {
  nuxtServerInit({ dispatch, commit }) {
    return Promise.all([
      dispatch('catalog/fetchCategoryConfig'),
      dispatch('catalog/fetchWarehouseConfig'),
      commit(`order/${orderMutation.SET_DELIVERY_METHODS}`, DefaultDeliveryMethods)
    ]);
  },
  getPageBySlug(context, params) {
    return this.$api.ContentService.getPageBySlug(params.slug, params.lang);
  }
};
