<template>
  <div>
    <div
      v-for="item in visibleColors"
      :key="`nomeclature-color-${item.color}`"
      :class="{
        active: [item.slug, item.color].includes(activeColor),
        disabled: !item.isAvailable,
        [itemClassName]: true
      }"
      :style="{ background: item.cssBackground }"
      @click="handleChangeColor(item)"
    >
      <slot :item="item">
        <div v-if="!isCatalog" class="color" :style="{ background: item.cssBackground }" />
      </slot>
    </div>
    <div v-if="isCatalog && colors.length > visibleColorsCount" class="other-colors">
      +{{ colors.length - visibleColorsCount }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'UcColorList',
  props: {
    colors: {
      type: Array,
      required: true
    },
    activeColor: {
      type: String,
      required: true
    },
    visibleColorsCount: {
      type: Number,
      default: 0
    },
    itemClassName: {
      type: String,
      required: true
    }
  },
  computed: {
    isCatalog() {
      return Boolean(this.visibleColorsCount);
    },
    visibleColors() {
      if (!this.isCatalog) {
        return this.colors;
      }
      return this.colors.slice(0, this.visibleColorsCount);
    }
  },
  methods: {
    handleChangeColor(color) {
      this.$emit('change', color);
    }
  }
};
</script>
