<template>
  <img :src="imgSrc" :alt="alt" />
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      required: true
    },
    alt: {
      type: String,
      required: true
    },
    withBase: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    imgSrc() {
      if (!this.withBase) {
        return this.src;
      }
      return `${process.env.BASE_IMG_URL}${this.src}`;
    }
  }
};
</script>
