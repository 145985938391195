<template>
  <div v-if="!isOneSlide" class="image-select-wrapper">
    <swiper v-if="isReset" :options="updatedConfig">
      <swiper-slide v-for="(video, index) in videos" ref="image-select-swiper" :key="`video-${index}`">
        <div
          class="swiper-slide-image youtube-button-slider"
          :class="[isVideoVisible ? 'display-none' : '']"
          @click="isScreenMobile ? displayVideo() : setMainImg({ type: 'video', link: video })"
        >
          <img :src="`https://img.youtube.com/vi/${parseYoutubeId(video)}/maxresdefault.jpg`" alt="youtube" />
        </div>
        <iframe :class="[isVideoVisible ? '' : 'display-none']" :src="video" width="530" height="430" />
      </swiper-slide>
      <swiper-slide v-if="rotateImages.length">
        <div v-if="!isScreenMobile" class="swiper-slide-image" @click="setMainImg({ type: 'rotateImages' })">
          <img src="https://static.chicco.com.ua/assets/svg/detail/360-ico.svg" alt="360" />
        </div>
        <div v-if="isScreenMobile" class="swiper-slide-image">
          <uc-image :img="rotateImages[0]" :width="`1000`" />
        </div>
      </swiper-slide>
      <swiper-slide
        v-for="(image, index) in currentImages"
        :key="`product-image-${index}-${image.link ? image.link : ''}`"
      >
        <div
          class="swiper-slide-image"
          @click="
            setMainImg({
              type: 'image',
              link: image.link,
              alt: image.alt,
              title: image.title
            })
          "
        >
          <uc-image :img="image.link" :alt="image.alt" :title="image.title" :width="`573`" :is-slide="true" />
        </div>
      </swiper-slide>
      <div slot="pagination" class="swiper-pagination" />
    </swiper>
    <div v-if="isSlidesEnough" class="swiper-button-prev"></div>
    <div v-if="isSlidesEnough" class="swiper-button-next"></div>
  </div>
</template>

<script>
// import RotateImage from './RotateImage.vue';
import ImageMixin from './mixin/Image.mixin.js';
import ScreenWidthMixin from '../../mixin/ScreenWidth';
import { productModels } from 'u-models';
const MainImageModel = productModels.mainImage;

export default {
  // components: {
  //   RotateImage
  // },
  mixins: [ScreenWidthMixin, ImageMixin],
  props: {
    sliderConfig: {
      type: Object,
      default: () => ({
        slidesPerView: 6,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        breakpoints: {
          1365: {
            slidesPerView: 5
          },
          1023: {
            slidesPerView: 5
          },
          767: {
            slidesPerView: 5
          }
        }
      })
    },
    videos: {
      type: Array,
      default: () => []
    },
    images: {
      type: Array,
      default: () => []
    },
    rotateImages: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      isReset: true,
      currentImages: [],
      isVideoVisible: false
    };
  },
  computed: {
    isSlidesEnough() {
      const FULLHD_COUNT = 4;
      const DESKTOP_COUNT = 3;
      const slidesDefaultNumber = this.isScreenFullHd ? FULLHD_COUNT : DESKTOP_COUNT;
      return this.videos.length + this.images.length + Boolean(this.rotateImages.length) > slidesDefaultNumber;
    },
    isOneSlide() {
      return this.videos.length + this.images.length + Boolean(this.rotateImages.length) === 1;
    },
    updatedConfig() {
      const config = { ...this.sliderConfig };

      if (!this.isSlidesEnough) {
        config.breakpoints['1920'].loop = false;
        config.breakpoints['1224'].loop = false;
      } else {
        config.breakpoints['1920'].loop = true;
        config.breakpoints['1224'].loop = true;
      }

      return config;
    }
  },
  watch: {
    images: {
      handler(images) {
        this.isReset = false;
        this.currentImages = images;
        this.$nextTick(() => {
          this.isReset = true;
        });
      },
      immediate: true
    },
    screenWidth() {
      this.isReset = false;
      this.isVideoVisible = false;
      this.$nextTick(() => {
        this.isReset = true;
      });
    }
  },
  mounted() {
    this.currentImages = this.images;
  },
  methods: {
    parseYoutubeId(url) {
      const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
      const match = url.match(regExp);

      return match && match[7].length == 11 ? match[7] : false;
    },
    setMainImg(item) {
      this.$emit('selected-item', new MainImageModel(item));
    },
    displayVideo() {
      this.isVideoVisible = true;
    }
  }
};
</script>
