import { ContentServices } from 'u-services';
const Service = ContentServices.MainService;

export default {
  asyncData($context) {
    const mainPageService = new Service($context);

    return Promise.all([
      mainPageService.getMainFirstSlider($context.app.i18n.locale),
      mainPageService.getMainSecondSlider($context.app.i18n.locale),
      mainPageService.getNewsSlider($context.app.i18n.locale),
      mainPageService.getBlogSlider($context.app.i18n.locale),
      mainPageService.getCategoriesSlides($context.app.i18n.locale),
      mainPageService.getFirstProductSlider($context.app.i18n.locale),
      mainPageService.getSecondProductSlider($context.app.i18n.locale),
      mainPageService.getMainDescription($context.app.i18n.locale)
    ])
      .then((data) => $context.$utils.clearResponseData(data))

      .catch((error) => {
        if (error.response && error.response.data.code && error.response.data.code === 404) {
          return null;
        }

        console.error('Main ssr Error:', error);
      });
  },
  data() {
    return {
      mainFirstSlides: [],
      mainSecondSlides: [],
      newsSlider: {},
      blogSlider: {},
      firstProductSlider: {},
      secondProductSlider: {},
      mainDescription: ''
    };
  }
};
