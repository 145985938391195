<template>
  <main class="main-page">
    <LazyHydrate when-idle>
      <section class="promotions">
        <div class="container">
          <main-slider :slides="mainFirstSlides" />
          <main-slider :slides="mainSecondSlides" :slug="'second-slider'" />
          <uc-link
            v-if="mainFirstSlides.length || mainSecondSlides.length"
            :to="localePath({ name: 'promotion-page' })"
            class="uc-button uc-button-blue-and-white btn-show-more"
            >{{ $t('Показать все акции') }}</uc-link
          >
        </div>
      </section>
    </LazyHydrate>

    <div class="container">
      <div class="shop-info">
        <uc-link :to="localePath({ name: 'info-page', params: { slug: 'service-warranty' } })" class="shop-info__item">
          <img src="https://static.chicco.com.ua/chicco-assets/svg/stroller.svg" alt="" />
          {{ $t('Гарантия до 36 месяцев') }}
        </uc-link>
        <uc-link :to="localePath({ name: 'info-page', params: { slug: 'delivery' } })" class="shop-info__item">
          <img src="https://static.chicco.com.ua/chicco-assets/svg/delivery-car.svg" alt="" />
          {{ $t('Бесплатная доставка по Киеву за 24 часа') }}*
        </uc-link>
        <uc-link :to="localePath({ name: 'info-page', params: { slug: 'bonus-rules' } })" class="shop-info__item">
          <img :src="`https://static.chicco.com.ua/assets/svg/ma-cashback_${$i18n.locale}.svg`" alt="ma-cashback" />
          {{ $t('Кешбэк за каждую покупку') }}
        </uc-link>
      </div>

      <LazyHydrate when-visible>
        <news :news-slides="newsSlider" />
      </LazyHydrate>

      <LazyHydrate when-visible>
        <product-slider
          :gtm-enabled="true"
          :products="firstProductSlider.products"
          :title="firstProductSlider.title"
          slug="firstProductSlider"
        />
      </LazyHydrate>

      <LazyHydrate when-visible>
        <categories :slides="categories" />
      </LazyHydrate>
    </div>
    <subscription />
    <div class="container">
      <LazyHydrate when-visible>
        <product-slider
          :gtm-enabled="true"
          :products="secondProductSlider.products"
          :title="secondProductSlider.title"
          slug="secondProductSlider"
        />
      </LazyHydrate>

      <LazyHydrate when-visible>
        <blog :blog-slides="blogSlider" />
      </LazyHydrate>
    </div>
    <div v-if="false" class="instagram-gallery">
      <div class="container">
        <div class="instagram-gallery__heading">
          <img src="https://static.chicco.com.ua/chicco-assets/svg/footer/instagram-icon.svg" alt="instagram" />
          Chiccoukraine
        </div>
      </div>
      <div class="instagram-gallery__list">
        <a
          ref="nofollow"
          target="_blank"
          href="https://www.instagram.com/chiccoukraine/"
          class="instagram-gallery__list-item"
        >
          <uc-image
            :img="'instagram-gallery.jpg'"
            folder="chicco-assets/markup"
            class="instagram-img"
            :width="'288'"
            :height="'288'"
            alt="Child"
            title="image"
          />
        </a>
        <a
          ref="nofollow"
          target="_blank"
          href="https://www.instagram.com/chiccoukraine/"
          class="instagram-gallery__list-item"
        >
          <uc-image
            :img="'instagram-gallery.jpg'"
            folder="chicco-assets/markup"
            class="instagram-img"
            :width="'288'"
            :height="'288'"
            alt="Child"
            title="image"
          />
        </a>
        <a
          ref="nofollow"
          target="_blank"
          href="https://www.instagram.com/chiccoukraine/"
          class="instagram-gallery__list-item"
        >
          <uc-image
            :img="'instagram-gallery.jpg'"
            folder="chicco-assets/markup"
            class="instagram-img"
            :width="'288'"
            :height="'288'"
            alt="Child"
            title="image"
          />
        </a>
        <a
          ref="nofollow"
          target="_blank"
          href="https://www.instagram.com/chiccoukraine/"
          class="instagram-gallery__list-item"
        >
          <uc-image
            :img="'instagram-gallery.jpg'"
            folder="chicco-assets/markup"
            class="instagram-img"
            :width="'288'"
            :height="'288'"
            alt="Child"
            title="image"
          />
        </a>
        <a
          ref="nofollow"
          target="_blank"
          href="https://www.instagram.com/chiccoukraine/"
          class="instagram-gallery__list-item"
        >
          <uc-image
            :img="'instagram-gallery.jpg'"
            folder="chicco-assets/markup"
            class="instagram-img"
            :width="'288'"
            :height="'288'"
            alt="Child"
            title="image"
          />
        </a>
        <a
          ref="nofollow"
          target="_blank"
          href="https://www.instagram.com/chiccoukraine/"
          class="instagram-gallery__list-item"
        >
          <uc-image
            :img="'instagram-gallery.jpg'"
            folder="chicco-assets/markup"
            class="instagram-img"
            :width="'288'"
            :height="'288'"
            alt="Child"
            title="image"
          />
        </a>
        <a
          ref="nofollow"
          target="_blank"
          href="https://www.instagram.com/chiccoukraine/"
          class="instagram-gallery__list-item"
        >
          <uc-image
            :img="'instagram-gallery.jpg'"
            folder="chicco-assets/markup"
            class="instagram-img"
            :width="'288'"
            :height="'288'"
            alt="Child"
            title="image"
          />
        </a>
        <a
          ref="nofollow"
          target="_blank"
          href="https://www.instagram.com/chiccoukraine/"
          class="instagram-gallery__list-item"
        >
          <uc-image
            :img="'instagram-gallery.jpg'"
            folder="chicco-assets/markup"
            class="instagram-img"
            :width="'288'"
            :height="'288'"
            alt="Child"
            title="image"
          />
        </a>
        <a
          ref="nofollow"
          target="_blank"
          href="https://www.instagram.com/chiccoukraine/"
          class="instagram-gallery__list-item"
        >
          <uc-image
            :img="'instagram-gallery.jpg'"
            folder="chicco-assets/markup"
            :width="'288'"
            :height="'288'"
            class="instagram-img"
            alt="Child"
            title="image"
          />
        </a>
        <a
          ref="nofollow"
          target="_blank"
          href="https://www.instagram.com/chiccoukraine/"
          class="instagram-gallery__list-item"
        >
          <uc-image
            :img="'instagram-gallery.jpg'"
            folder="chicco-assets/markup"
            :width="'288'"
            :height="'288'"
            class="instagram-img"
            alt="Child"
            title="image"
          />
        </a>
      </div>
    </div>

    <LazyHydrate when-visible>
      <main-description :description="mainDescription" />
    </LazyHydrate>
  </main>
</template>

<script>
import MainSlider from '~/src/components/main/mainSlider/MainSlider.vue';
import News from '~/src/components/main/News.vue';
import Blog from '~/src/components/main/Blog.vue';
import ProductSlider from '~/src/components/main/ProductSlider.vue';
import Categories from '~/src/components/main/Categories.vue';
import MainDescription from '~/src/components/page-blocks/MainDescription.vue';
import Subscription from '~/src/components/general/Subscription.vue';
import MainSsrMixin from '~/src/components/main/mixins/MainSsr.mixin.js';
import LazyHydrate from 'vue-lazy-hydration';

export default {
  name: 'MainPage',
  components: {
    MainSlider,
    News,
    Blog,
    ProductSlider,
    Categories,
    MainDescription,
    Subscription,
    LazyHydrate
  },
  mixins: [MainSsrMixin]
};
</script>

<style lang="scss">
@import '~@/assets/scss/main/main.scss';
</style>
