<template>
  <section v-click-outside="closeSearchResult" class="quick-search__block">
    <form ref="search" class="quick-search-form" @submit.prevent="submit()">
      <input
        ref="searchField"
        v-model="search"
        class="quick-search-form__field"
        type="text"
        :placeholder="$t(placeholder)"
        aria-label="Поиск"
        @input="(event) => setValue(event.target.value)"
        @click="focusHandler"
        @keyup.enter="enterHandler()"
      />
      <div v-show="search.length" class="quick-search-icon quick-search-icon__clear" @click="resetQuickSearch()">
        <img src="https://static.chicco.com.ua/assets/svg/search-clear.svg" alt="search-clear" />
      </div>
      <div
        v-show="!search.length"
        ref="voiceTrigger"
        class="quick-search-icon quick-search-icon__voice"
        @click.prevent="listenStart()"
      >
        <img src="https://static.chicco.com.ua/assets/svg/header/voice-search-ico.svg" alt="search-voice" />
      </div>
      <div :class="{ 'display-none': search.length }" class="quick-search-icon quick-search-icon__search">
        <img src="https://static.chicco.com.ua/assets/svg/header/search-loupe-ico.svg" alt="search" />
      </div>
      <div :class="{ 'is-opened': isDisplaySearchResult }" class="quick-search-result">
        <div ref="search_list" class="quick-search-result-wrapper">
          <search-item
            v-for="product in products"
            :key="`quick-search-search-item-${product.id}`"
            :class="{ invisible: loader }"
            :product="product"
            @click="closeSearchResult"
          />
          <div :class="{ 'mobile-search-status-text': loader }">
            <div v-if="!loader && !products.length" class="quick-search-status-text">
              {{ $t('Ничего не найдено') }}...
            </div>
            <loader v-if="loader" />
          </div>
        </div>
        <div v-if="!loader && categories.length" class="quick-search-result_category">
          <div class="category-title">{{ $t('Категории') }}</div>
          <div class="category-links">
            <uc-link
              v-for="category in categories"
              :key="`quick-search-search-category-${category.slug}`"
              :to="
                localePath({
                  name: 'product-catalog-page',
                  params: getCategoryUrl(category)
                })
              "
              class="link"
            >
              <span>{{ category.title }}</span>
            </uc-link>
          </div>
        </div>
        <div v-if="products.length" class="quick-search-result_btn">
          <uc-link :to="localePath({ name: 'search-result-page', query: { q: search } })" class="link">
            <button type="submit" @click="closeSearchResult()">
              {{ $t('Все результаты') }}&nbsp;<span v-if="!loader">({{ total }})</span>
            </button>
          </uc-link>
        </div>
      </div>
    </form>
  </section>
</template>

<script>
import { mapMutations, mapActions } from 'vuex';
import vClickOutside from 'v-click-outside';

import { RouterMixin, NavigationRouteMixin, ThrottledMixin } from 'u-mixins';
import { SET_QUICK_SEARCH_REQUEST } from 'u-catalog/lib/store/mutation-types.js';

import SearchItem from './UcSearchItem.vue';
import ScreenWidthMixin from '../mixin/ScreenWidth';
import Loader from './Loader.vue';

const MIN_SEARCH_TEXT_LENGTH = 2;
const DELAY = 300;

export default {
  directives: {
    clickOutside: vClickOutside.directive
  },
  components: {
    SearchItem,
    Loader
  },
  mixins: [RouterMixin, NavigationRouteMixin, ScreenWidthMixin, ThrottledMixin],
  data() {
    return {
      search: '',
      products: [],
      categories: [],
      isDisplaySearchResult: false,
      loader: false,
      total: 0,
      recognition: null,
      placeholder: 'Поиск'
    };
  },
  computed: {
    isLengthEnought() {
      return this.search.length >= MIN_SEARCH_TEXT_LENGTH;
    }
  },
  watch: {
    $route: {
      handler() {
        this.resetQuickSearch();
      },
      deep: true
    }
  },
  mounted() {
    this.$eventBus.$on('quick-search:result:close', this.closeSearchResult);
    this.$eventBus.$on('quick-search:reset-search', this.resetQuickSearch);

    const SpeechRecognition = global.SpeechRecognition || global.webkitSpeechRecognition;
    const voiceTrigger = this.$refs.voiceTrigger;
    if (SpeechRecognition) {
      this.recognition = new SpeechRecognition();
      this.recognition.lang = 'ru-RU';
      this.recognition.addEventListener('result', this.transcriptHandler);
      this.recognition.onspeechend = () => {
        voiceTrigger.classList.remove('voice__active');
        this.placeholder = 'Поиск';
        this.recognition.stop();
      };

      this.recognition.onerror = (event) => {
        if (event.error == 'no-speech') {
          voiceTrigger.classList.remove('voice__active');
          this.placeholder = 'Поиск';
          this.recognition.stop();
        }
      };
    } else {
      this.$refs.voiceTrigger.remove();
    }
  },
  beforeDestroy() {
    this.$eventBus.$off('quick-search:result:close', this.closeSearchResult);
    this.$eventBus.$off('quick-search:reset-search', this.resetQuickSearch);
  },
  methods: {
    ...mapActions({
      getProductByTitle: 'catalog/getProductByTitle',
      getCategoryByTitle: 'catalog/getCategoryByTitle'
    }),
    ...mapMutations({
      [SET_QUICK_SEARCH_REQUEST]: `catalog/${SET_QUICK_SEARCH_REQUEST}`
    }),
    setValue(value) {
      this.search = value;
      this.throttledFunction(this.submit, DELAY);
    },
    async submit() {
      if (!this.isLengthEnought) {
        return null;
      }

      this.SET_QUICK_SEARCH_REQUEST(this.search);

      this.loader = true;
      this.isDisplaySearchResult = true;

      const getProducts = this.getProductByTitle(this.search).then(({ data }) => {
        this.products = data.items;
        this.total = data.pagination.total;
      });

      const getCategories = this.getCategoryByTitle(this.search).then(({ data }) => {
        this.categories = (data?.items && data.items.slice(0, 3)) || [];
      });
      try {
        await Promise.all([getProducts, getCategories]);
        this.SET_QUICK_SEARCH_REQUEST(this.search);
      } catch (error) {
        this.$sentry?.captureException(error);
        console.error('Quick Search Error:', error);
      } finally {
        this.loader = false;
      }
    },
    closeSearchResult() {
      if (!this.isDisplaySearchResult) {
        return null;
      }

      if (this.search.length && this.isScreenMobile) {
        this.$refs.searchField.blur();
      }

      this.isDisplaySearchResult = false;
    },
    focusHandler() {
      if (this.search && this.products.length) {
        this.isDisplaySearchResult = true;
      }
    },
    resetQuickSearch() {
      this.setValue('');
      this.products = [];
      this.categories = [];
      if (this.$refs.voiceTrigger) {
        this.$refs.voiceTrigger.classList.remove('voice__active');
      }
    },
    listenStart() {
      this.recognition.stop();
      this.placeholder = 'Говорите...';

      if (this.search.length) {
        this.setValue('');
      }
      if (this.$refs.voiceTrigger) {
        this.$refs.voiceTrigger.classList.add('voice__active');
      }
      this.recognition.start();
    },
    parseTranscript(e) {
      return Array.from(e.results)
        .map(function (result) {
          return result[0];
        })
        .map(function (result) {
          return result.transcript;
        })
        .join('');
    },
    transcriptHandler(e) {
      const speechOutput = this.parseTranscript(e);
      this.setValue(speechOutput);
      if (e.results[0].isFinal) {
        this.$refs.voiceTrigger.classList.remove('voice__active');
        this.recognition.stop();
      }
    },
    enterHandler() {
      if (!this.isLengthEnought) {
        return null;
      }

      if (this.isScreenMobile) {
        this.isDisplaySearchResult = false;
        this.$refs.searchField.blur();
      }

      if (this.$route.name === `search-result-page___${this.$i18n.locale}` && this.$route.query.q === this.search) {
        this.closeSearchResult();
      } else {
        this.moveToByName('search-result-page', null, null, { q: this.search });
      }
    },
    getCategoryUrl(category) {
      return category.isFolder
        ? this.buildParams(category.slug)
        : this.buildParams(category.parentCategory.slug, category.slug);
    }
  }
};
</script>

<style scoped>
.loader {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
</style>
