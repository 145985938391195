import i18nConfig from '../i18n.conf.json';

export const getLocaleCodes = () => [
  ...i18nConfig.locales.map((lang) => lang.code),
  ...Object.keys(i18nConfig.localeReplaces)
];

export const getLocaleReplaceCode = (locale) => i18nConfig.localeReplaces[locale] || locale;
export const getLocaleRegexp = (locale) => new RegExp(`^/?(${locale})?(/.*|$|\\?.*)`);

export const getLocalizedRouteName = (name, locale) => `${name}${i18nConfig.routeLocalePrefix}${locale}`;
export const getRouteNameWithoutLocale = (routeName) => {
  const localeRegexp = new RegExp(`^(.*)${i18nConfig.routeLocalePrefix}(${getLocaleCodes().join('|')})?$`);
  const route = localeRegexp.exec(routeName);

  return (route && route[1]) || routeName;
};

export function getUrlLocale(path) {
  const langUrlRegexp = getLocaleRegexp(getLocaleCodes().join('|'));
  const langUrl = langUrlRegexp.exec(path);

  return (langUrl && langUrl[1]) || null;
}

export const replaceLocale = (path, fromLocale, toLocale) => {
  const regexp = getLocaleRegexp(fromLocale);

  return path.replace(regexp, (...match) => {
    const [, , other] = match;

    return `${((toLocale || other[0] !== '/') && '/') || ''}${toLocale || ''}${other}`;
  });
};

export const getUrlWithLocale = (path, toLocale) =>
  replaceLocale(path, getUrlLocale(path) || [...getLocaleCodes(), ''].join('|'), toLocale);
