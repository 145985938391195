<template>
  <section>
    <div v-if="slides.length" class="categories-wrapper">
      <div class="main-title">{{ $t('Товары по категориям') }}</div>
      <div class="categories-list" :class="{ active: showMore }">
        <div v-for="(slide, index) in slides" :key="`categories-item-${index}-${slide.img}`" class="category">
          <uc-link :to="slide.link" class="category-item">
            <div class="category__info">
              <div class="category__info-name">{{ slide.title }}</div>
              <div class="category__info-link">В каталог</div>
            </div>
            <div class="category__img">
              <uc-image :img="slide.img" folder="slider-image" :alt="slide.alt" :title="slide.title" />
            </div>
          </uc-link>
          <div class="category-name">{{ slide.title }}</div>
        </div>
      </div>
      <uc-button v-if="!showMore" class="uc-button-blue-and-white" @click="onShowMore">{{
        $t('Смотреть еще')
      }}</uc-button>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    slides: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      showMore: false
    };
  },
  methods: {
    onShowMore() {
      this.showMore = true;
    }
  }
};
</script>
