<template>
  <section>
    <div v-if="description" class="container tablet-container">
      <div class="main-description">
        <input id="main-description-button" v-model="checked" type="checkbox" />
        <label for="main-description-button" class="main-description-button">
          <span v-if="checked" class="main-description-button">{{ $t('Свернуть') }}</span>
          <span v-else class="main-description-button">{{ $t('Читать полностью') }}</span>
        </label>
        <div class="description-block">
          <uc-render-html root-element="section" class="description-block-text" :html="description" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'MainDescription',
  props: {
    description: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      checked: false
    };
  }
};
</script>

<style lang="scss">
@import '~/assets/scss/main-description/_main-description.scss';
</style>
