import { orderStore } from 'u-store';

const {
  state: orderState = {},
  mutations: orderMutations = {},
  actions: orderActions = {},
  getters: orderGetters = {}
} = orderStore;

export const state = orderState;

export const mutations = {
  ...orderMutations
};

export const actions = {
  ...orderActions
};

export const getters = {
  ...orderGetters
};
