const CATALOG_QUERY =
  ':category([\\w\\-%2F]+)*/:filter([\\w\\-]+[\\=|%3D][\\w\\-,%2F]+)*/:page(page-\\d+)?/:sort(sort-[a-z-]+)?';
const PAGE_PARAM = ':page(page-\\d+)*';

const routerPath = {
  main: '/',
  about: '/about',
  momsSchool: '/moms-school',
  lecture: '/moms-school/lecture',
  socialProject: '/social-project',
  notFoundErrorPage: '/404',
  uiKit: '/ui-kit',
  basket: '/basket',
  chiccoTime: '/chicco-time',
  product: '/shop/:id([\\w\\-]+)',
  page: '/info/:slug([\\w\\-]+)',
  order: '/order',
  orderHistory: `/order-history/${PAGE_PARAM}`,
  orderHistoryDetail: '/order-history/:id([\\w\\-]+)',
  userReviewPage: `/my-reviews/${PAGE_PARAM}`,
  profile: '/profile',
  emailConfirm: '/email-confirm/:token([\\w]+)',
  searchResult: '/search-result',
  thanksPayment: '/thanks/checkout/:paymentNumber',
  thanksOrder: '/thanks/:number',
  bonusProgram: '/bonus-program',
  inviteFriend: '/invite-friend',
  oauth: '/oauth/:provider(facebook|google)',
  oauthConnect: '/oauth/connect/:provider(facebook|google)',
  wishList: '/wish-list',
  publicWishList: '/wish-list/:hash',
  promotion: `/promotion/${PAGE_PARAM}`,
  promotionDetail: '/promotion/:slug([a-z0-9\\-_]+)',
  blog: `/blog/${PAGE_PARAM}`,
  blogDetail: '/blog/:slug([a-z0-9\\-_]+)',
  news: `/news/${PAGE_PARAM}`,
  newsDetail: '/news/:slug([a-z0-9\\-_]+)',
  personalArea: '/personal-area',
  brands: '/brands',
  brand: '/brand/:slug([\\w\\-]+)',
  healthCheck: '/_healthcheck',
  outlet: `/outlet/${CATALOG_QUERY}`,
  pakunokMalyuka: `/pakunok-malyuka/${CATALOG_QUERY}`,
  catalog: `/:folderCategory/${CATALOG_QUERY}`,
  subscriptionProfile: '/subscription-profile',
  customCatalog: `/catalogs/:slug/${CATALOG_QUERY}`
};

export default routerPath;
