<template>
  <ValidationProvider
    v-slot="{ errors }"
    :name="label || name"
    :rules="rules"
    :class="{ 'uc-input-has-value': innerValue }"
  >
    <slot name="before" />
    <slot v-if="label && !noLabel" name="label" :label="label">
      <label class="uc-input-label">{{ $t(label) }}</label>
    </slot>
    <textarea
      v-model="innerValue"
      :name="name"
      class="uc-textarea"
      :class="{ 'uc-invalid': errors.length, ...additionalClasses }"
    />
    <slot v-if="errors.length" name="error" :errors="errors">
      <span class="uc-error">{{ errors[0] }}</span>
    </slot>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

export default {
  components: { ValidationProvider },
  props: {
    value: {
      type: String,
      default: null
    },
    name: {
      type: String,
      required: true
    },
    rules: {
      type: [String, Object],
      default: ''
    },
    noLabel: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    inputClasses: {
      type: [String, Object],
      default: ''
    }
  },
  data() {
    return {
      innerValue: this.value
    };
  },
  computed: {
    additionalClasses() {
      if (typeof this.inputClasses === 'string') {
        return { [this.inputClasses]: true };
      }
      return this.inputClasses;
    }
  },
  watch: {
    value() {
      if (this.value !== this.innerValue) {
        this.innerValue = this.value;
      }
    },
    innerValue() {
      this.$emit('input', this.innerValue);
    }
  }
};
</script>

<style lang="scss" scoped>
.uc-input-label {
  position: static;
}
</style>
