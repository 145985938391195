<template>
  <ValidationProvider
    v-slot="{ errors }"
    ref="provider"
    :name="nameForProvider"
    :rules="rules"
    class="uc-input-field"
    :class="{ 'uc-input-has-value': innerValue }"
    :slim="isSlim"
    :vid="vid || name"
    :mode="mode"
  >
    <slot name="before" />
    <slot v-if="label && !noLabel" name="label" :label="label">
      <label class="uc-input-label" :class="{ 'is-required': isRequired && withStar }">
        {{ $t(label) }}
        <span v-if="isRequired && withStar">*</span>
      </label>
    </slot>
    <slot>
      <input
        v-model="innerValue"
        :name="name"
        :type="type"
        class="uc-input"
        :placeholder="placeholder"
        :step="step"
        :title="title"
        :class="{ 'uc-invalid': errors.length, ...additionalClasses }"
        :min="min"
        :max="max"
        @change="handleChange"
        @focus="handleInputFocus"
      />
    </slot>
    <slot name="errors" :errors="errors">
      <span v-if="errors.length" class="uc-error">
        {{ errors[0] }}
      </span>
    </slot>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

export default {
  components: {
    ValidationProvider
  },
  props: {
    value: {
      type: [Number, String, Object],
      default: ''
    },
    name: {
      type: String,
      required: true
    },
    rules: {
      type: [String, Object],
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    label: {
      type: String,
      default: ''
    },
    step: {
      type: String,
      default: ''
    },
    inputClasses: {
      type: [String, Object],
      default: ''
    },
    noLabel: {
      type: Boolean,
      default: false
    },
    isSlim: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    vid: {
      type: String,
      default: ''
    },
    clearFocus: {
      type: Boolean,
      default: false
    },
    max: {
      type: [String, Number],
      default: ''
    },
    min: {
      type: String,
      default: ''
    },
    mode: {
      type: String,
      default: 'aggressive'
    },
    withStar: {
      type: Boolean,
      default: true
    },
    lazy: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      innerValue: this.value
    };
  },
  computed: {
    nameForProvider() {
      if (this.label) {
        return this.$t(this.label);
      }
      return this.$t(this.name);
    },
    additionalClasses() {
      if (typeof this.inputClasses === 'string') {
        return { [this.inputClasses]: true };
      }
      return this.inputClasses;
    },
    isRequired() {
      if (typeof this.rules === 'string') {
        return this.rules.includes('required');
      }
      return this.rules?.required || false;
    }
  },
  watch: {
    value() {
      if (this.value !== this.innerValue) {
        this.innerValue = this.value;
      }
    },
    innerValue() {
      if (!this.lazy) {
        this.$emit('input', this.innerValue);
      }
    }
  },
  methods: {
    handleInputFocus() {
      if (this.clearFocus) {
        this.innerValue = '';
      }
    },
    handleChange() {
      if (!this.lazy) {
        return;
      }
      this.$emit('input', this.innerValue);
    }
  }
};
</script>

<style lang="scss" scoped>
.is-required {
  span {
    color: $red;
  }
}
</style>
