<template>
  <section
    v-touch:start="startDrag"
    v-touch:moving="doDrag"
    v-touch:end="stopDrag"
    class="image-wrapper rotate-image no_highlights"
    @mousedown.prevent="startDrag"
    @mousemove.prevent="doDrag"
    @mouseleave.prevent="stopDrag"
  >
    <div
      v-if="loading"
      :style="{ width: `${wrapperWidth}px`, height: `${wrapperHeight}px` }"
      class="image-loader-wrapper"
    >
      <uc-loader />
    </div>
    <div v-touch-class="'hidden'" :class="{ hidden: loading }">
      <img
        v-for="(img, index) in images"
        :key="`rotate-image-touch-${index}`"
        :class="{ hidden: index != current }"
        :src="getImgUrl(img)"
        @load="loadImg()"
      />
    </div>
  </section>
</template>

<script>
import ImageMixin from './mixin/Image.mixin.js';

export default {
  name: 'RotateImage',
  mixins: [ImageMixin],
  props: {
    images: {
      type: Array,
      default: () => []
    },
    width: {
      type: String,
      default: 'AUTO'
    },
    height: {
      type: String,
      default: 'AUTO'
    },
    folder: {
      type: String,
      default: 'images'
    }
  },
  data() {
    return {
      dragging: false,
      x: 0,
      startX: 0,
      current: 0,
      imagesLoaded: 0,
      max: 0,
      loading: true
    };
  },
  mounted() {
    global.addEventListener('mouseup', this.stopDrag);
  },
  beforeDestroy() {
    global.removeEventListener('mouseup', this.stopDrag);
  },
  methods: {
    loadImg() {
      this.max = this.images.length - 1;
      this.imagesLoaded++;
      if (this.imagesLoaded == this.images.length) {
        this.loading = false;
      }
    },
    startDrag(event) {
      this.dragging = true;
      this.startX = event.clientX || event.touches[0].clientX;
    },
    stopDrag(event) {
      this.dragging = false;
      this.x = event.clientX;
    },
    doDrag(event) {
      this.x = event.clientX || event.touches[0].clientX;
      if (this.dragging) {
        if (this.x - this.startX > 3) {
          this.current + 1 > this.max ? (this.current = 0) : (this.current += 1);
          this.startX = this.x;
        }
        if (this.startX - this.x > 3) {
          this.current - 1 < 0 ? (this.current = this.max) : (this.current -= 1);
          this.startX = this.x;
        }
      }
    }
  }
};
</script>
