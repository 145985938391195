<template>
  <n-link :to="to">
    <slot></slot>
  </n-link>
</template>

<script>
export default {
  name: 'UcLink',
  props: {
    to: {
      type: [Object, String],
      required: true
    }
  }
};
</script>
