<template>
  <li v-if="visibility" class="filter-item">
    <span class="uc-discount" :class="{ 'uc-discount-active': isActive }" @click="clickHandler()">
      <input v-model="isChecked" type="checkbox" />
      <slot></slot>
    </span>
  </li>
</template>

<script>
export default {
  name: 'UcSize',
  props: {
    value: {
      type: Array,
      default: () => []
    },
    current: {
      type: String,
      default: ''
    },
    visibility: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isChecked: false
    };
  },
  computed: {
    isActive() {
      return this.value.find((item) => item === this.current);
    }
  },
  watch: {
    value: {
      handler() {
        this.isChecked = (this.value || []).includes(this.current);
      },
      immediate: true
    }
  },
  methods: {
    clickHandler() {
      this.isChecked = !this.isChecked;
      let data = [...this.value];
      if (this.isChecked) {
        data.push(this.current);
      } else {
        data = data.filter((val) => val !== this.current);
      }

      this.$emit('click', this.current);
      this.$emit('input', data);
    }
  }
};
</script>
