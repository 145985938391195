<template>
  <div class="loading-spinner-wrapper">
    <div class="loading-spinner">
      <object
        type="image/svg+xml"
        data="https://static.chicco.com.ua/assets/svg/chicco_loader.svg"
        alt="spinner"
        class="spinning-square"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoadingSpinner'
};
</script>

<style lang="scss">
.loading-spinner-wrapper {
  height: calc(100vh - 140px);

  @media (min-width: 1366px) and (max-width: 1920px) {
    height: calc(100vh - 147px);
  }

  @media (min-width: 1024px) and (max-width: 1365px) {
    height: calc(100vh - 140px);
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    height: calc(100vh - 100px);
  }

  @media (min-width: 576px) and (max-width: 767px) {
    height: calc(100vh - 12vw);
  }

  @media (max-width: 575px) {
    height: calc(100vh - 14vw);
  }

  @media (max-width: 480px) {
    height: calc(100vh - 17vw);
  }
}

.loading-spinner {
  height: 100%;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinning-square {
  width: 200px;
}
</style>
