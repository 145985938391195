import { reviewStore } from 'u-store';

const { state: reviewState = {}, mutations: reviewMutations = {}, actions: reviewActions = {} } = reviewStore;

export const state = reviewState;

export const mutations = {
  ...reviewMutations
};

export const actions = {
  ...reviewActions
};
