export default () => {
  const cookie_name = 'deduplication_cookie';
  const days_to_store = 90;

  const getSourceParamFromUri = function () {
    const utm = document.location.search.match(/utm_source=([^&]+)/i);
    const gclid = document.location.search.match(/gclid=([^&]+)/i);

    if (utm && gclid) {
      return utm[1];
    } else if (gclid) {
      return gclid[1];
    } else if (utm) {
      return utm[1];
    } else {
      return '';
    }
  };

  const setSourceCookie = function () {
    const param = getSourceParamFromUri();

    if (!param) {
      return;
    }
    const period = days_to_store * 60 * 60 * 24 * 1000;
    const expiresDate = new Date(period + +new Date());
    const cookieString = cookie_name + '=' + param + '; path=/; expires=' + expiresDate.toGMTString();
    document.cookie = cookieString;
    document.cookie = cookieString + '; domain=.' + location.host;
  };

  setSourceCookie();

  const admitad = document.createElement('script');

  admitad.async = true;
  admitad.src = 'https://www.artfut.com/static/tagtag.min.js?campaign_code=1fffad291b';
  admitad.onerror = admitadErrorHandler;

  document.head.appendChild(admitad);

  const admitadErrorHandler = () => {
    const self = this;
    const ADMITAD = window.ADMITAD || {};
    (window.ADMITAD = window.ADMITAD || {}),
      (ADMITAD.Helpers = ADMITAD.Helpers || {}),
      (ADMITAD.Helpers.generateDomains = function () {
        const e = new Date(),
          n = Math.floor(new Date(2020, e.getMonth(), e.getDate()).setUTCHours(0, 0, 0, 0) / 1e3),
          t = parseInt(1e12 * (Math.sin(n) + 1)).toString(30),
          i = ['de'],
          o = [];
        for (let a = 0; a < i.length; ++a) o.push({ domain: t + '.' + i[a], name: t });
        return o;
      }),
      (ADMITAD.Helpers.findTodaysDomain = function (e) {
        function n() {
          const o = new XMLHttpRequest(),
            a = i[t].domain,
            D = 'https://' + a + '/';
          o.open('HEAD', D, !0),
            (o.onload = function () {
              setTimeout(e, 0, i[t]);
            }),
            (o.onerror = function () {
              ++t < i.length ? setTimeout(n, 0) : setTimeout(e, 0, void 0);
            }),
            o.send();
        }
        let t = 0;
        const i = ADMITAD.Helpers.generateDomains();
        n();
      }),
      (window.ADMITAD = window.ADMITAD || {}),
      ADMITAD.Helpers.findTodaysDomain(function (e) {
        if (((window.ADMITAD.dynamic = e), window.ADMITAD.dynamic)) {
          const n = (function () {
              return function () {
                return self.src ? self : '';
              };
            })(),
            t = n(),
            i = (/campaign_code=([^&]+)/.exec(t.src) || [])[1] || '';
          t.parentNode.removeChild(t);
          const o = document.getElementsByTagName('head')[0],
            a = document.createElement('script');
          (a.src =
            'https://www.' +
            window.ADMITAD.dynamic.domain +
            '/static/' +
            window.ADMITAD.dynamic.name.slice(1) +
            window.ADMITAD.dynamic.name.slice(0, 1) +
            '.min.js?campaign_code=' +
            i),
            o.appendChild(a);
        }
      });
  };
};
