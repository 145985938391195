<template>
  <uc-link
    :to="localePath({ name: 'product-detail-page', params: { id: product.slug } })"
    class="search-result__item"
    @click.native="$eventBus.$emit('quick-search:result:close')"
  >
    <div class="result-item__info">
      <div class="item-info__img">
        <uc-image :img="getImgLinkByOrder(product.images)" :alt="title" :width="`50`" />
      </div>
      <div class="item-info__title">
        {{ title }}
      </div>
    </div>
    <div v-show="product.totalAmount" class="result-item__price">
      <div
        v-if="product.probablyPriceRecommended && product.probablyPriceRecommended !== product.probablyPriceSelling"
        class="item-price__old"
      >
        {{ product.probablyPriceRecommended | currency }}
      </div>
      <div class="item-price__new">
        {{ product.probablyPriceSelling | currency }}
      </div>
    </div>
    <div v-show="!product.totalAmount" class="result-item__not-available">
      <span>{{ $t('Нет в наличии') }}</span>
    </div>
  </uc-link>
</template>

<script>
export default {
  props: {
    product: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    title() {
      return this.product.title ? this.product.title.replace(/&quot;/g, "'") : '';
    }
  },
  methods: {
    getImgLinkByOrder(items) {
      try {
        const images = this.$utils.cloneObj(items);

        if (!images.length) {
          return null;
        }

        if (images.length === 1) {
          return images[0].link;
        }

        const sortImages = images.sort(function (a, b) {
          return a.order - b.order;
        });

        return sortImages[0].link;
      } catch (e) {
        console.error('Get Image Link By Order Error:', e);
      }
    }
  }
};
</script>
