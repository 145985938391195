<template>
  <div class="product-additional-info labels">
    <div v-if="isNew" class="card-plate card-plate_new">
      <span class="card-plate__title">new</span>
    </div>
    <div v-if="isPreOrder" class="card-plate card-plate_preorder">
      <span class="card-plate__title">Предзаказ</span>
    </div>
    <div v-if="discount" class="card-plate card-plate_discount">
      <span class="card-plate__title">{{ `-${discount}%` }}</span>
    </div>
    <div v-if="false" class="card-plate">
      <span class="card-plate__title">топ</span>
    </div>
    <div v-if="false" class="card-plate card-plate_new">
      <span class="card-plate__title">акция</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isNew: {
      type: Boolean,
      default: false
    },
    isPreOrder: {
      type: Boolean,
      default: false
    },
    discount: {
      type: Number,
      default: null
    }
  }
};
</script>
