import Vue from 'vue';
import VueLazyLoad from 'vue-lazyload';

Vue.use(VueLazyLoad, {
  preLoad: 0,
  attempt: 3,
  lazyComponent: false,
  observer: true,
  throttleWait: 500
});
